import { Cancel, Schedule } from '@mui/icons-material';
import { Avatar, Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';


export default function UcBusinessHeadUploadResult(props) {
    const [loadingschedue, setLoadingschedue] = useState(false);
    // Add unique 'id' property to each row in props.resultSet

    const columns = [
        { field: 'id', headerName: 'ID', headerAlign: 'left', align: 'left', width: 100, hide: true },
        {
            field: 'message', headerName: 'Remarks', headerAlign: 'left', align: 'left', width: 535,
            renderCell: (cell) => {
                return (<Tooltip title={cell.row.message}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.message) === true ? "" : cell.row.message}</Typography></Tooltip>);
            },
        }
    ]

    return (
        <>
            <CardHeader style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 4 }} titleTypographyProps={{ variant: 'body1' }} title="File Upload Status" avatar={<Avatar sx={{ color: 'orange', backgroundColor: 'white' }} aria-label="Approval"><Schedule /></Avatar>} action={<div style={{ marginTop: 0 }}><IconButton onClick={() => props.onClose('')}><Cancel sx={{ fontSize: 25}} className='App-Icons'/></IconButton></div>} />
            <div style={{ padding: 4 }}>
                <Card variant="outlined" style={{ width: 'auto' }} >
                    <CardContent style={{ justifyContent: 'center', padding: 0 }}>
                        <Box sx={{ height: 195, width: '100%' }}>
                            <DataGrid
                                sx={{
                                    border: '1px solid black',
                                    fontSize: 13,
                                    '& .MuiDataGrid-iconSeparator': { display: 'inline-grid' },
                                    '& .css-levciy-MuiTablePagination-displayedRows': { marginTop: 'revert' },
                                    '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: '1px solid black' },
                                }}
                                rowHeight={25}
                                headerHeight={25}
                                rows={props.resultSet}                             
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[100]}
                                loading={loadingschedue}
                                getRowId={(row) => row.id}                               
                            />

                        </Box>
                    </CardContent>
                </Card>
            </div>
        </>
    )
}