import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import { Route, BrowserRouter, Routes,Navigate  } from 'react-router-dom';
import { Layout } from  '../layout/Layout'
import { Home } from './Home';
import { HuddleHome } from './HuddleHome';
import { Report } from './Report';
import { Dashboard } from './Dashboard';
import { Actions } from './Actions';
import {Unauthorised} from '../ui-component/Login/Unauthorised'
import {GetAPI} from '../Utilities/API/GetAPI';
import {ApiURLs} from '../Utilities/API/ApiURLs';
import { useDispatch } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';
import { Add_User_Details} from "../State/actionTypes";
import {LoadingSpinner} from "../layout/Loader/LoadingSpinner"
import { CustomAlert } from '../Utilities/CustomAlert';
import {IsNullOrEmptyOrUndefined} from '../Utilities/Utilities';

export default function Main() {
    //create a flag variable check user exists in Pulsemeter(Authorizes user)
    const [unauthorizedUser, setunauthorizedUser] = useState(false)
    const [employeeid, setEmployeeid] = useState(0)
    const [loginuserdetails, setLoginUserDetails] = useState(0)
    const [selectedFeedbackId, setSelectedFeedbackId] = useState(0);
    const dataFetchedRef = useRef(false);
    //to store data in redux store
    const dispatch = useDispatch();
    //to select value from redux store
    //create flag variable for loading process
    const [isLoading, setIsLoading] = useState(true);
    const handleError = useErrorBoundary();
    const userinfo = useSelector((state) => state.userDetailsReducer.userDetails);

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;


        //set loading to true
        setIsLoading(true);
       
        // use getapi method use get (url,token=null)
        GetAPI(ApiURLs.UserDetails).then((response) => {
            //set loading to false
            setIsLoading(false);
            //check response has error
            if (response.error) {

                //check if you are expecting specif error
                if (response.error === 404 || response.error === 403) {
                    //set unauthorizedUser to true
                    setunauthorizedUser(true);
                } else {
                    //through exception here
                    handleError("Server Error :" + response.error);
                }
            }
            else {

                //response is correct and set the unauthorizedUser to false
                setunauthorizedUser(false);
                setEmployeeid(response.employeeid);
                //add the user details in redux store so we can use it in the application
                dispatch({ type: Add_User_Details, userDetails: response });
                setLoginUserDetails(response);
            }
        })
            .catch((e) => { console.log(e); handleError(e); });
    }, [dispatch, employeeid, handleError, loginuserdetails]);

    useEffect(() => {
        getFeedbackTypeDetails();
    }, [selectedFeedbackId]);

    const getFeedbackTypeDetails = () => {
        setIsLoading(true);
        GetAPI(ApiURLs.FeedbackTypeDetails).then((response) => { 
            setIsLoading(false);   
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                }
                else
                    if (response.error === 400) {
                        CustomAlert(response.msg, 'error');
                    }
                    else {
                        CustomAlert(response.msg, 'error');
                    }
            }
            else {
                setSelectedFeedbackId(response.selectedFeedbackId);
              }
            })
            .catch((e) => {
                setIsLoading(false);
                CustomAlert(e, 'error');
            });
      }   


    return (
        <>
        {
            isLoading ? <LoadingSpinner /> : unauthorizedUser ? <Unauthorised /> :
                <BrowserRouter>
                    <Layout>
                        <Routes>    
                            <Route exact path='/' element={<Suspense fallback={<>loading...</>}><Home /></Suspense>} />
                            <Route path="/Home" element={<Suspense fallback={<>loading...</>}><Home /></Suspense>} />
                            <Route path="/Dashboard" element={(userinfo.groups.includes('Admin') || userinfo.groups.includes('HR')) ? <Suspense fallback={<>loading...</>}><Dashboard /></Suspense> : <Suspense fallback={<>loading...</>}><Unauthorised /></Suspense>} /> 
                            <Route path="/Report" element={(userinfo.groups.includes('Admin') || userinfo.groups.includes('HR')) ? <Suspense fallback={<>loading...</>}><Report /></Suspense> : <Suspense fallback={<>loading...</>}><Unauthorised /></Suspense>} /> 
                            <Route path="/Actions" element={(userinfo.groups.includes('Admin') || userinfo.groups.includes('HR')) ? <Suspense fallback={<>loading...</>}><Actions /></Suspense> : <Suspense fallback={<>loading...</>}><Unauthorised /></Suspense>} />                                            
                            <Route path="/Unauthorised" element={<Suspense fallback={<>loading...</>}><Unauthorised /></Suspense> } />
                            <Route path="*" element={<Suspense fallback={<>loading...</>}><PageNotFound /></Suspense>} />                     
                        </Routes>
                    </Layout>
                </BrowserRouter>
        }           
        </>
    )

}

function PageNotFound() {
    return (
        <div>
            <h2>404 Page not found</h2>
        </div>
    );
}