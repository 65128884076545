import React, { useEffect, useState } from 'react';
import { Card, Grid, Box, Typography, CardContent,Button, IconButton,Tooltip,Dialog} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ClearIcon from '@mui/icons-material/Clear';
import Switch from '@mui/material/Switch';
import  UcFeedbackSetupAddEmoticon  from './UcFeedbackSetupAddEmoticon';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PutAPI } from '../../Utilities/API/PutAPI';
import { DeleteAPI } from '../../Utilities/API/DeleteAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import { BorderRight } from '@mui/icons-material';
import Swal from 'sweetalert2';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../src/App.css';


export function UcFeedbackSetup()
{
  const label = { inputProps: { 'aria-label': 'Enable/Disable' } };
  const [selectedImage, setSelectedImage] = useState(null);
  const [openrequest, setopenrequest] = useState(false);
  const [fisttimeload, setFirstTimeLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isDatafound, setIsDatafound] = React.useState(true);
  const [feedbackTypeList, setFeedbackTypeList] = useState([]);
  const [checked, setChecked] = useState(true);

  const images = require.context('../../Images/', true);

  const imageList = images.keys().map(image => ({
        src: images(image),
        title: image.replace('./', '').replace(/\.[^/.]+$/, ''),
        imgName: image.replace('./', ''),
  }));
  

  const handleImageClick = (imgId) => {
    setSelectedImage(imgId);
  };
  const handleClose = () => {
    setopenrequest(false);
    getFeedbackTypeSetupDetailsList();
  }

  useEffect(() => {
    if(fisttimeload == true){
      getFeedbackTypeSetupDetailsList();
    }
  },[fisttimeload]);

  const getFeedbackTypeSetupDetailsList = () => {
    setFirstTimeLoad(false);
    setLoading(true);
    GetAPI(ApiURLs.GetFeedbackTypeSetupDetailsList).then((response) => { 
      setLoading(false);   
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert('Unauthrize error, try to login again', 'error');
            }
            else
                if (response.error === 400) {
                    CustomAlert(response.msg, 'error');
                }
                else {
                    CustomAlert(response.msg, 'error');
                }
        }
        else {       
          const updatedList = response.map(group => ({
            ...group,
            feedbackTypeSetupList: group.feedbackTypeSetupList.map(item => ({
              ...item,
              active: item.active
            }))
          }));
          setFeedbackTypeList(updatedList);
          setIsDatafound(true);    
        }      
        })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
  };
  const updateFeedbackTypeSetup = (feedbackTypeSetupId, isChecked, originalChecked, groupIndex, imgIndex) => {
    setLoading(true);
    var url = ApiURLs.UpdateFeedbackTypeSetup;
    var model = { feedbackTypeSetupId, active: isChecked ? 1 : 0 };
    PutAPI(url, model).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert('Unauthorized error, try to login again', 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
            revertSwitchState(originalChecked, groupIndex, imgIndex); // Revert state on error
        }
        else {
            CustomAlert(response.message, 'success');
        }
    })
    .catch((e) => {
        setLoading(false);
        CustomAlert(e, 'error');
        revertSwitchState(originalChecked, groupIndex, imgIndex); // Revert state on error
    });
  };
  
  
  const deleteFeedbackTypeSetup = (feedbackTypeSetupId) => {
    setLoading(true);    
    DeleteAPI(ApiURLs.DeleteFeedbackTypeSetup + '?FeedbackTypeSetupId=' + feedbackTypeSetupId).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert('Unauthrize error, try to login again', 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
        }
        else {
            CustomAlert(response.message, 'success');   
            getFeedbackTypeSetupDetailsList();  
           
        }
    })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
  };
  const getImageSrc = (imageName) => {
    const image = imageList.find(img => img.imgName === imageName);
    return image ? image.src : '';
  };
  const handleSwitchChange = (event, imgIndex, groupIndex) => {
    const isChecked = event.target.checked;
    const originalChecked = feedbackTypeList[groupIndex].feedbackTypeSetupList[imgIndex].active;
    
    const updatedFeedbackTypeList = [...feedbackTypeList];
    updatedFeedbackTypeList[groupIndex].feedbackTypeSetupList[imgIndex].active = isChecked;
    setFeedbackTypeList(updatedFeedbackTypeList);
  
    updateFeedbackTypeSetup(updatedFeedbackTypeList[groupIndex].feedbackTypeSetupList[imgIndex].feedbackTypeSetupId, isChecked, originalChecked, groupIndex, imgIndex);
  };
  const revertSwitchState = (originalChecked, groupIndex, imgIndex) => {
    const updatedFeedbackTypeList = [...feedbackTypeList];
    updatedFeedbackTypeList[groupIndex].feedbackTypeSetupList[imgIndex].active = originalChecked;
    setFeedbackTypeList(updatedFeedbackTypeList);
  };
  const handledelete = (feedbackTypeSetupId) => {
    Swal.fire({
        text: 'Are you sure do you want to delete emoticon?',
        icon: 'warning',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        allowOutsideClick: false,
        backdrop: true,
    }).then((result) => {
        if (result.isConfirmed) {
            deleteFeedbackTypeSetup(feedbackTypeSetupId);

        }
    })
  };

      return (
        <>
        <Card variant="outlined" style={{ marginTop: 10 }} >
        <CardContent style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>
            <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 12, width: 150, marginLeft: 10}} onClick={() => setopenrequest(true)} startIcon={<AddIcon style ={{fontSize: 16}} />}>Add Emoticons</Button>           
        </CardContent>
        </Card>
        { fisttimeload ?  
          (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </div> ) :
          (<Card sx={{ p: 3, mb: 3 }} style={{ marginTop: 10 }}>
            <Grid container spacing={2}>
              {feedbackTypeList.map((group, index) => (
                <Grid item xs={12} key={index}>
                  <Box border={1} borderColor="grey.300" borderRadius={2} p={2}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={1} style={{ textAlign: 'center' }}>
                        <Typography className="App-FontColor" style={{ fontSize: 20, fontFamily: 'Serif' }}>{group.feedbackTypeName}</Typography>
                      </Grid>
                      <Grid item xs={10} >
                        <Grid container spacing={1}>
                          {group.feedbackTypeSetupList.map((img, imgIndex) => (
                            <Grid item key={img.feedbackTypeSetupId} >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height={100}
                                width={100}
                                // border={selectedImage === img.feedbackTypeSetupId ? '2px solid #4caf50' : 'none'}
                                borderRadius="10%"
                                overflow="hidden"
                                // onClick={() => handleImageClick(img.feedbackTypeSetupId)}
                                sx={{ cursor: 'pointer', padding: '10px' }}
                              >
                                <img
                                  src={getImageSrc(img.feedbackImageURL)}
                                  alt={img.feedbackImageURL}
                                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                />
                              </Box>
                              <Box mt={1} display="flex" justifyContent="center" width="100%" BorderRight='1px solid #4caf50'>
                                <Tooltip title="Enable/Disable">
                                  <Switch className='App-Icons' {...label} checked={img.active} onChange={(e) => handleSwitchChange(e, imgIndex, index)} sx={{ transform: 'scale(0.75)', marginRight: '-8px' }} />
                                </Tooltip>                             
                                <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteForeverIcon className='App-Icons' sx={{ transform: 'scale(0.85)' }} onClick={() => handledelete(img.feedbackTypeSetupId)}/>
                                </IconButton>
                              </Tooltip>
                              </Box>
                            </Grid>                         
                          ))}
                        </Grid>
                      </Grid>                 
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Card>)
        }
        <Dialog
          open={openrequest} 
          onClose={handleClose}
          fullWidth={true} height={1000} maxWidth={'lg'}
          >
          <UcFeedbackSetupAddEmoticon
              open={openrequest}
              onClose={handleClose}
          />
        </Dialog>
      </>
    );
}