//will use environment file to get base url
const BASE_URL = process.env.REACT_APP_API;

 
export const ApiURLs = {
    'UserDetails': BASE_URL + '/api/UserDetails/UserDetails',
    'ProxyUser': BASE_URL + '/api/UserDetails/ProxyUser',
    'RemoveProxyUser': BASE_URL + '/api/UserDetails/RemoveProxyUser',
    'FeedbackTypeDetails' : BASE_URL + '/api/EmployeeFeedback/FeedbackTypeDetails',
    'EmployeeFeedback' : BASE_URL + '/api/EmployeeFeedback/EmployeeFeedback',
    'UserDetails_DeviceToken': BASE_URL + '/api/UserDetails/DeviceToken',
    'EmployeeAccess': BASE_URL + '/api/UserDetails/EmployeeAccessFeatures',
    'GetCountryList': BASE_URL + '/api/BUHeadMapping/CountryList',
    'GetDivisionList': BASE_URL + '/api/BUHeadMapping/DivisionList',
    'GetBusinessUnitList': BASE_URL + '/api/BUHeadMapping/BusinessUnitList',
    'AddBusinessUnitHeadDetails': BASE_URL + '/api/BUHeadMapping/AddBusinessUnitHeadDetails',
    'GetBusinessUnitHeadDetailsByPage': BASE_URL + '/api/BUHeadMapping/GetBusinessUnitHeadDetailsByPage',
    'UpdateBusinessUnitHeadDetails': BASE_URL + '/api/BUHeadMapping/UpdateBusinessUnitHeadDetails', 
    'DownloadBUHeadMappingTemplate': BASE_URL + '/api/BulkUploadBUHeadMapping/DownloadBUHeadMappingTemplate',
    'UploadTemplate': BASE_URL + '/api/BulkUploadBUHeadMapping/UploadTemplate', 
    'EmployeeList': BASE_URL + '/api/Search/EmployeeList', 
    'GroupList': BASE_URL + '/api/UserAccessManagement/GroupList',
    'EmployeeDetailsById': BASE_URL + '/api/UserAccessManagement/EmployeeDetailsById',
    'UserGroupMappingList': BASE_URL + '/api/UserAccessManagement/UserGroupMappingList',
    'AddUserRoleMapping': BASE_URL + '/api/UserAccessManagement/AddUserRoleMapping',
    'UpdateUserRoleMapping': BASE_URL + '/api/UserAccessManagement/UpdateUserRoleMapping',
    'GetFeedbackTypeList': BASE_URL + '/api/FeedbackTypeSetup/GetFeedbackTypeList',
    'AddFeedbackTypeSetup': BASE_URL + '/api/FeedbackTypeSetup/AddFeedbackTypeSetup',
    'GetFeedbackTypeSetupDetailsList': BASE_URL + '/api/FeedbackTypeSetup/GetFeedbackTypeSetupDetailsList',
    'UpdateFeedbackTypeSetup': BASE_URL + '/api/FeedbackTypeSetup/UpdateFeedbackTypeSetup',
    'DeleteFeedbackTypeSetup': BASE_URL + '/api/FeedbackTypeSetup/DeleteFeedbackTypeSetup',
    'AccountCountryList': BASE_URL + '/api/BUAccountManagement/CountryList',
    'AccountDivisionList': BASE_URL + '/api/BUAccountManagement/DivisionList',
    'AccountBusinessUnitList': BASE_URL + '/api/BUAccountManagement/BusinessUnitList',
    'AddBUAccountDetails': BASE_URL + '/api/BUAccountManagement/AddBUAccountDetails',
    'BUAccountMappingList': BASE_URL + '/api/BUAccountManagement/BUAccountMappingList',
    'UpdateBUAccountDetails': BASE_URL + '/api/BUAccountManagement/UpdateBUAccountDetails',
    'UserNavigation': BASE_URL + '/api/Dashboard/UserNavigation',
    'BusinessUnitHeadList': BASE_URL + '/api/Dashboard/BusinessUnitHeadList',
    'EmployeeBandList': BASE_URL + '/api/Dashboard/EmployeeBandList',
    'AllFeedbackCountDetails': BASE_URL + '/api/Dashboard/AllFeedbackCountDetails',
    'FeedbackCountByLocation': BASE_URL + '/api/Dashboard/FeedbackCountByLocation',
    'FeedbackCountByGender': BASE_URL + '/api/Dashboard/FeedbackCountByGender',
    'FeedbackCountByTenure': BASE_URL + '/api/Dashboard/FeedbackCountByTenure',
    'FeedbackCountByBand': BASE_URL + '/api/Dashboard/FeedbackCountByBand',
    'FeedbackCountTrend': BASE_URL + '/api/Dashboard/FeedbackCountTrend',
    'ConsumptionPercentage': BASE_URL + '/api/Dashboard/ConsumptionPercentage',
    'ReportCountryList': BASE_URL + '/api/Reports/CountryList',
    'ReportDivisionList': BASE_URL + '/api/Reports/DivisionList',
    'ReportAccountList': BASE_URL + '/api/Reports/AccountList',
    'ReportBusinessUnitList': BASE_URL + '/api/Reports/BusinessUnitList',
    'PulseReport': BASE_URL + '/api/Reports/PulseReport',
    'GeneratedReports': BASE_URL + '/api/Dashboard/GeneratedReports',
    'Download': BASE_URL + '/api/Dashboard/Download',
    'EmployeeFeedbackDetails': BASE_URL + '/api/Dashboard/EmployeeFeedbackDetails',
    'InsertHuddle' : BASE_URL + '/api/Huddle/InsertHuddle',
    'GetHuddlesByDateRange' : BASE_URL + '/api/Huddle/GetHuddlesByUser',
    'DownloadAttachment' : BASE_URL + '/api/Huddle/DownloadAttachment',
    'GetDRsList' : BASE_URL + '/api/Huddle/GetDRsList',
    'UpdateHuddle' : BASE_URL + '/api/Huddle/UpdateHuddle',
    'GetDRsHuddleCount' : BASE_URL + '/api/Huddle/GetDRsHuddleCount',
}
