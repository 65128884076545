import { Cancel, Schedule, SentimentSatisfiedAlt } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoopIcon from '@mui/icons-material/Loop';
import { Avatar, Badge, Box, Card, CardContent, CardHeader, CircularProgress, Fab, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { PostDownloadAPI } from "../Utilities/API/PostDownloadAPI";
import { PostAPI } from '../Utilities/API/PostAPI';
import { Constant } from '../Utilities/Constant';
import { CustomAlert } from '../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { LoadingButton } from '@mui/lab';
export function UcGeneratedReports(props) {

    dayjs.extend(relativeTime);
    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        page: 0,
        pageSize: 10
    })

    useEffect(() => {
        // const interval = setInterval(() => {
        //     getGeneratedReports()
        // }, 10000);
        // getGeneratedReports();
        // return () => clearInterval(interval);
        getGeneratedReports()

    }, [pageState.page, pageState.pageSize]);

    const getGeneratedReports = () => {
        setLoading(true);
        setPageState(old => ({ ...old, isLoading: true }))
        var url = ApiURLs.GeneratedReports;
        var parameters = { pageSize: pageState.pageSize, pageNum: pageState.page===0?1:(pageState.page+1) };
        PostAPI(url, parameters).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setPageState(old => ({ ...old, isLoading: false, data: response, total: response.length > 0 ? response[0].totalCount : 0 }))
            }
        })
            .catch((e) => {
                setLoading(false);
                setPageState(old => ({ ...old, isLoading: false, data: [], total: 0, page: 1, pageSize: 10 }));
                CustomAlert(e, 'error');
            });
    }


    const columns = [
        // { field: 'id', headerName: 'id', hide: true },
        {
            field: "displayFileName", headerName: 'File Name', headerAlign: 'left', width: 200, align: 'left', sortable: true,
            renderCell: (cell) => {
                return (
                    <Typography fontSize={13}>{cell.row.displayFileName}</Typography>
                );
            }
        },
        {
            field: "createdDate", headerName: 'Created Date', headerAlign: 'left', width: 200, align: 'left', sortable: true,
            renderCell: (cell) => {
                return (
                    <Typography fontSize={13}>{dayjs(cell.row.createdDate).format(Constant.dayformat_time)}</Typography>
                );
            }
        },
        {
            field: "comment", headerName: 'Remark', headerAlign: 'left', width: 180, align: 'left', sortable: true,
            renderCell: (cell) => {
                return (
                    <Tooltip title={cell.row.comment}><Typography fontSize={13}>{cell.row.comment}</Typography></Tooltip>
                );
            }
        },
        {
            field: "download", headerName: '', headerAlign: 'left', align: 'center', flex: 1, sortable: false,
            renderCell: (cell) => {
                return (
                    cell.row.active ?
                        <IconButton disableElevation variant="contained" size="small" style={{ height: 25, textTransform: 'none' }} onClick={() => onDownload(cell.row.id, cell.row.displayFileName)}>
                            <Badge max={99} ><FileDownloadIcon sx={{ color: '#282D59' }} /></Badge>
                        </IconButton>
                        :
                        <IconButton disabled disableElevation variant="contained" size="small" style={{ height: 25, textTransform: 'none' }}>
                            <Badge max={99} ><LoopIcon sx={{ color: '#282D59' }} /></Badge>
                        </IconButton>

                );
            }
        }
    ]
    const handleClose = () => {
        props.onClose();
    }

    const NoRowsOverlay = () => {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <SentimentSatisfiedAlt sx={{ color: '#e68a00' }} /> No pending request
            </Stack>
        );
    }
    const onDownload = (id, filename) => {

        var url = ApiURLs.Download + "/" + id;
        PostDownloadAPI(url, filename).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {

            }
        }).catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    }

    return (
        <>
            <CardHeader style={{ paddingTop: 4, paddingLeft: 0, paddingBottom: 10 }} titleTypographyProps={{ variant: 'body1' }} title="Downloads" avatar={<Avatar sx={{ color: '#282D59', backgroundColor: 'white' }} aria-label="Approval"><Schedule /></Avatar>}       
            action={
                <div>    
                    {/* <IconButton onClick={getGeneratedReports} variant="contained" size="medium" style={{ height: 30, textTransform: 'none' }}>
                        <Badge max={99} ><LoopIcon sx={{ color: '#0057b7' }} /></Badge>
                    </IconButton>              */}
                    <LoadingButton loading={loading} className="App-Button" title="Refresh" disableElevation color='primary' variant="contained" style={{ textTransform: 'none', fontSize: 13, width: 100, height: 30, marginTop: 5, marginRight: 20 }} onClick={getGeneratedReports} >Refresh</LoadingButton>
                    <IconButton  onClick={handleClose}><Cancel className="App-Icons" sx={{ fontSize: 25 }} /></IconButton>    
                </div>
            } 
            />
           
            {
                loading &&
                <Card variant="outlined" style={{ height: 380, border: 0 }}>
                    <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', position: 'absolute', minWidth: '100%', left: 0, top: '46%' }}><Fab disabled={true} style={{ backgroundColor: '#fff', boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(75, 22, 22, 0.12)' }}>{<CircularProgress size={35} style={{ color: '#1976d2' }} />}</Fab></div>

                    </CardContent>
                </Card>
            }
            {
                (loading === false) &&
                <div style={{ padding: 4, paddingTop: 0 }}>

                    <Card variant='outlined' style={{ justifyContent: 'center', padding: 0 }}>
                        <Box sx={{ height: 350, width: '100%' }}>
                        <DataGrid
                        getRowId={(row) => row.id}
                        disableColumnMenu
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pageSizeOptions={[10, 30, 50, 70, 100]}
                        pagination                    
                        paginationMode="server"
                        onPaginationModelChange={(e) => {
                        setPageState(old => ({ ...old, page: e.page, pageSize:e.pageSize}))
                        }}
                        paginationModel={pageState}
                        columns={columns}
                        getRowHeight={() => 'auto'} headerHeight={31}
                        className="App-Icons"
                        sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: 0 } }}
                    />
                    </Box>
                    </Card>

                </div>
            }
        </>
    )
}