import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { IconButton,Card,CardHeader, Button,TextField, Divider,Grid, Typography,Box,InputLabel, MenuItem, Select,FormControl} from '@mui/material';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';



export default function UcFeedbackSetupAddEmoticon(props) {
    const images = require.context('../../Images/', true);
    const imageList = images.keys().map(image => ({
        src: images(image),
        title: image.replace('./', '').replace(/\.[^/.]+$/, ''),
        imgName: image.replace('./', ''),     
    }));

    const [selectedImage, setSelectedImage] = useState(null);
    const [feedbackType, setFeedbackType] = useState([]);
    const [selectedFeedbackType, setSelectedFeedbackType] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const newErrors = {};

    const handleImageClick = (imageName) => {
        setSelectedImage(imageName);
    };
    useEffect(() => {
        getFeedbackTypeList();
      }, []);
  
      
    const getFeedbackTypeList = () => {
    setLoading(true);
    var url = ApiURLs.GetFeedbackTypeList; 
    GetAPI(url).then((response) => {
        setLoading(false);
        if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 404) {
                CustomAlert('Unauthrize error, try to login again', 'error');
            } else {
                CustomAlert(response.msg, 'error');
            }
        }
        else {
            setFeedbackType(response);           
        }
    })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    };
    const handleFeedbackTypeChange = (event) => {
        if (errors.selectedFeedbackType) {
            delete errors.selectedFeedbackType;
        }
        const selectedValue = event.target.value;
        setSelectedFeedbackType(selectedValue);
    }; 
    const addFeedbackTypeSetup= () => {
        setLoading(true);
        var parameters = {feedbackTypeId: selectedFeedbackType, feedbackImageURL: selectedImage};
        PostAPI(ApiURLs.AddFeedbackTypeSetup,parameters).then((response) => { 
          setLoading(false);  
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                }
                else if (response.error === 400) {
                      CustomAlert(response.msg, 'error');
                }
              }                 
            else {               
                  CustomAlert(response.message, 'success'); 
                  //props.onClose();
            }       
            })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
      };
      const errorStyle = {
        borderColor: 'red',
        borderWidth: 1,
    };
    const onSubmit = () => { 
        if (!selectedFeedbackType) newErrors.selectedFeedbackType = 'Feedback Type is required';       
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            if(!selectedImage){
                CustomAlert('Please select an emoticons.', 'error');
                return;
            }
            addFeedbackTypeSetup();
        }
    };

    return (
        <>
        <Box p={3}>
            <Grid container justifyContent="flex-end">
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClose()}>
                <CloseIcon className='App-Icons'/>
                </IconButton>
            </Grid>
            <Typography variant="h6" component="h6">
                Add Emoticons
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{marginTop:2}}>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedFeedbackType}>
                    <InputLabel style={errors.selectedFeedbackType ? errorStyle : {}}>Feedback Type</InputLabel>
                    <Select  
                        value={selectedFeedbackType}
                        onChange={handleFeedbackTypeChange}      
                        label="Feedback Type"
                        style={errors.selectedFeedbackType ? errorStyle : {}}
                    >
                        <MenuItem value="" disabled>Select Feedback Type</MenuItem>
                        {feedbackType.map((row) => (
                        <MenuItem key={row.id} value={row.id}>{row.feedbackTypeName}</MenuItem>
                        ))}
                    </Select> 
                    {errors.selectedFeedbackType && <div style={{ color: '#d32f2f', fontSize: '0.75rem'}}>{errors.selectedFeedbackType}</div>}                             
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button variant="contained" className='App-Button' style={{ textTransform: 'none', width: 120,fontSize: 15}} startIcon={<AddIcon style ={{marginRight: -4}}/>} onClick={onSubmit}>Add</Button>
                </Grid>
        
            </Grid>
            <Grid container spacing={2} style={{ marginTop: 2 }}>
                    {imageList.map((image, index) => (
                        <Grid item xs={4} sm={2} key={index} style={{ textAlign: 'center' }} onClick={() => handleImageClick(image.imgName)}>
                            <img
                            
                                src={image.src}
                                alt={image.title}
                                style={{ width: '60%', height: 'auto', display: 'block', margin: '0 auto',
                                    border: selectedImage === image.imgName ? '2px solid #4caf50' : 'none',
                                    cursor: 'pointer',
                                    borderRadius: '10%'
                                 }}
                            />
                            <Typography variant="subtitle2" style={{ textAlign: 'center' }}>{image.title}</Typography>
                        </Grid>
                    ))}
            </Grid>
        </Box>
        </>
    )
}