import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import DashboardSidebar from './DashboardSidebar';
import DashboardFilters from './DashboardFilters';
import DashboardPieChart from './DashboardPieChart';
import DashboardTenurewiseBarChart from './DashboardTenurewiseBarChart';
import DashboardLocationwise from './DashboardLocationwise';
import DashboardGenderwise from './DashboardGenderwise';
import DashboardBandwise from './DashboardBandwise';
import DashboardWeeklyTrendwise from './DashboardWeeklyTrendwise';
import DashboardConsumptionChart from './DashboardConsumptionChart';

export function Dashboard() {

    const [filterModel, setFilterModel] = useState({
        selectedCountry: null,
        selectedDivision: null,
        selectedAccount: null,
        selectedBusinessUnit: null,
        selectedBusinessHead: [],
        selectedBand: [],
        startDate: new Date(),
        endDate: new Date(),
        ClearAll: false,
        selectedName: '',
    });

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
          
          {/* Sidebar Section */}
          <Box sx={{
            width: { xs: '100%', sm: '25%', md: '100%' },
            position: { sm: 'fixed', xs: 'relative' },
          }}>
            <DashboardSidebar filterModel={filterModel} setFilterModel={setFilterModel} />
          </Box>
    
          {/* Main Content Section */}
          <Box sx={{
            marginLeft: { xs: '32%',sm: '32%', md: '16%', },
            width: { xs: '100%', sm: '75%', md: '100%' },
            padding: 1,
          }}>
            
            {/* Filters Section */}
            <Box mb={1}>
              <DashboardFilters filterModel={filterModel} setFilterModel={setFilterModel} />
            </Box>
    
            {/* Graphs Section */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DashboardPieChart filterModel={filterModel} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardTenurewiseBarChart filterModel={filterModel} />
              </Grid>
            </Grid>
    
            <Grid container spacing={1} paddingTop={1}>
              <Grid item xs={12} sm={6}>
                <DashboardLocationwise filterModel={filterModel} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardGenderwise filterModel={filterModel} />
              </Grid>
            </Grid>
    
            <Grid container spacing={1} paddingTop={1}>
              <Grid item xs={8}>
                <DashboardBandwise filterModel={filterModel}/>
              </Grid>
              <Grid item xs={4}>
                <DashboardConsumptionChart filterModel={filterModel}/>
              </Grid>
            </Grid>
    
            <Grid container spacing={1} paddingTop={1}>
              <Grid item xs={12}>
                <DashboardWeeklyTrendwise filterModel={filterModel}/>
              </Grid>
            </Grid>
            
          </Box>
        </Box>
      );
    }
