import dayjs from 'dayjs';

export const ValidateEmail = (email) => {
    if (email.length > 50) {
        return (false)
    }
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
    }
    return (false)
}
export const IsToday = (Value) => {
    const today = new Date()
    return Value.getDate() === today.getDate() && Value.getMonth() === today.getMonth() && Value.getFullYear() === today.getFullYear()
}
export const IsDateEqual = (dt1, dt2) => {
    var result = dt1.getDate() === dt2.getDate() && dt1.getMonth() === dt2.getMonth() && dt1.getFullYear() === dt2.getFullYear()
    return result;
}
export const IsNullOrEmptyOrUndefined = (value) => {
    if (value === null || value === undefined || value === '') {
        return true
    }
    else {
        return false
    }
}
export const ConvertNullToEmpty = (value) => {
    return (value === null || value === undefined) ? '' : value;
}
export const ToDateFormat = (date, format) => {
    let str = ''
    if (date !== undefined && date !== null && date !== '') {
        var myDate = new Date(date);
        let newformat = '';
        switch (format) {
            case "D MMM YYYY": {
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
                str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
                break;
            }
            case "D MMM YYYY HH:MM": {
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
                var hours = myDate.getHours();
                var minutes = myDate.getMinutes();

                newformat = hours >= 12 ? 'PM' : 'AM';// Check whether AM or PM                
                hours = hours % 12;// Find current hour in AM-PM Format                
                hours = hours ? hours : 12;// To display "0" as "12"
                minutes = minutes.toString().padStart(2, '0');
                str = myDate.getDate() + ' ' + month + ' ' + myDate.getFullYear() + ' ' + hours + ':' + minutes + ' ' + newformat
                break;
            }
            case "D MMM YYYY HH:MM:SS": {
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][myDate.getMonth()];
                let hours = myDate.getHours();
                let minutes = myDate.getMinutes();

                newformat = hours >= 12 ? 'PM' : 'AM';// Check whether AM or PM                
                hours = hours % 12;// Find current hour in AM-PM Format                
                hours = hours ? hours : 12;// To display "0" as "12"
                minutes = minutes.toString().padStart(2, '0');
                var seconds = myDate.getSeconds().toString().padStart(2, '0');
                str = myDate.getDate() + ' ' + month + ' ' + myDate.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + newformat
                break;
            }
            case "D MMMM YYYY": {
                const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][myDate.getMonth()];
                str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
                break;
            }
            case "D MMMM YYYY HHMM": {
                const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][myDate.getMonth()];
                str = myDate.getDate() + " " + month + " " + myDate.getFullYear() + " " + myDate.getHours() + ":" + myDate.getMinutes();
                break;
            }
            case "D/M/YYYY": {
                str = myDate.getDate() + "/" + (myDate.getMonth() + 1) + "/" + myDate.getFullYear();
                break;
            }
            case "D": {
                str = myDate.getDate();
                break;
            }
            default:
                str = ''
                break;
        }
    }
    return str;
}
export const CalculateAge = (dateOfBirth) => {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = dateOfBirth

    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";
    var dateAge = ''
    var monthAge = ''

    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
        monthAge = monthNow - monthDob;
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
        dateAge = dateNow - dateDob;
    else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    age = { years: yearAge, months: monthAge, days: dateAge };

    if (age.years > 1) yearString = " years";
    else yearString = " year";
    if (age.months > 1) monthString = " months";
    else monthString = " month";
    if (age.days > 1) dayString = " days";
    else dayString = " day";


    if ((age.years > 0) && (age.months > 0) && (age.days > 0))
        ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString;
    else if ((age.years === 0) && (age.months === 0) && (age.days > 0))
        ageString = "Only " + age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days === 0))
        ageString = age.years + yearString;
    else if ((age.years > 0) && (age.months > 0) && (age.days === 0))
        ageString = age.years + yearString + " and " + age.months + monthString;
    else if ((age.years === 0) && (age.months > 0) && (age.days > 0))
        ageString = age.months + monthString + " and " + age.days + dayString;
    else if ((age.years > 0) && (age.months === 0) && (age.days > 0))
        ageString = age.years + yearString + " and " + age.days + dayString;
    else if ((age.years === 0) && (age.months > 0) && (age.days === 0))
        ageString = age.months + monthString;
    else ageString = "Oops! Could not calculate age!";

    return ageString;
}
export const BirthOfYearValidation = (year) => {
    var result = true;
    var text = /^[0-9]+$/;
    try {
        if (year.toString().length === 4) {
            if (year !== 0) {
                if ((year !== '') && (!text.test(year))) {
                    result = false;
                }
                if (year.toString().length !== 4) {
                    result = false;
                }
                var current_year = new Date().getFullYear();
                if ((Number(year) < 1920) || (Number(year) > current_year)) {
                    result = false;
                }
            }
        }
        else {
            result = false;
        }
    }
    catch {
        result = false
    }
    return result;
}
export const YearValidation = (year) => {
    var result = true;
    var text = /^[0-9]+$/;
    try {
        if (year.toString().length === 4) {
            if (year !== 0) {
                if ((year !== '') && (!text.test(year))) {
                    result = false;
                }
                if (year.toString().length !== 4) {
                    result = false;
                }
                var current_year = new Date().getFullYear();
                if ((Number(year) > current_year)) {
                    result = false;
                }
            }
        }
        else {
            result = false;
        }
    }
    catch {
        result = false
    }
    return result;
}
export const ValidateExtention = (ext, type) => {
    var result = false;
    try {

        if (IsNullOrEmptyOrUndefined(ext) === false && IsNullOrEmptyOrUndefined(type) === false) {

            switch (type) {
                case 'document':
                    var extarray = ['ppt', 'pptx', 'pdf']
                    if (extarray.filter(x => x === ext.toLowerCase()).length > 0) {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                    break;
                case 'video':
                    var videoarray = ['mp4', 'mov', 'webm']
                    if (videoarray.filter(x => x === ext.toLowerCase()).length > 0) {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                    break;
                case 'image':
                    var iarray = ['jpeg', 'jpg', 'png', 'gif', 'ico']
                    if (iarray.filter(x => x === ext.toLowerCase()).length > 0) {
                        result = true;
                    }
                    else {
                        result = false;
                    }
                    break;
                default:
                    result = false;
                    break;
            }

        }
    }
    catch {
        result = false
    }
    return result;
}
export const formatFileSize = (bytes, decimalPoint) => {
    if (bytes === 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const IsMobileNumber = (str) => {
    var i, len = str.length;
    let charCode;
    if (len > 12 || len < 10) {
        return false;
    }

    for (i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
    }
    return true;
}
export const ShortNumber = (number, decPlaces) => {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = Math.round(number * decPlaces / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if ((number === 1000) && (i < abbrev.length - 1)) {
                number = 1;
                i++;
            }

            // Add the letter for the abbreviation
            number += abbrev[i];

            // We are done... stop
            break;
        }
    }
    return number;
}
export const TitleCase = (str) => {
    if (IsNullOrEmptyOrUndefined(str) === false) {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    }
    else
        return '';
}
export const Display12HourFormat = (dt) => {
    var date = new Date(dt);
    var hours = date.getHours();
    var minutes = date.getMinutes();

    // Check whether AM or PM
    var newformat = hours >= 12 ? 'PM' : 'AM';

    // Find current hour in AM-PM Format
    hours = hours % 12;

    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return hours + ':' + minutes + ' ' + newformat;
}
export const MonthDiff = (dateFrom, dateTo) => {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}
export const DayDiff = (dateStart, dateEnd) => {
    var time_difference = dateEnd.getTime() - dateStart.getTime();
    var days_difference = time_difference / (1000 * 60 * 60 * 24);
    return Math.ceil(days_difference);
}
export const TimeDiff = (dt) => {
    const past = dayjs(dt);
    const today = dayjs();

    let month = today.diff(past, 'month');
    let yr = today.diff(past, 'year');

    let mnt = month - (yr * 12);
    return yr + ' years and ' + mnt + ' months.'
}
export const TimeDiffInHHMM = (dt) => {
    const past = dayjs(dt);
    const today = dayjs();

    let month = today.diff(past, 'month');
    let yr = today.diff(past, 'year');

    let mnt = month - (yr * 12);
    return yr + ' years and ' + mnt + ' months.'
}
export const setCookieWithNoExpiry = (cName, cValue) => {
    document.cookie = cName + "=" + cValue;
}
export const setCookie = (key, value) => {
    let date = new Date();
    date.setDate(date.getDate() + 30);
    const expires = "expires=" + date.toUTCString();
    const cookie = [key, '=', value, '; ', expires, '; path=/;'].join('');
    document.cookie = cookie;
}
export const getCookie = (cName) => {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}
export const checkCookie = (cName) => {
    let user = sessionStorage.getItem(cName);
    if (IsNullOrEmptyOrUndefined(user) === false)
        return true;
    else
        return false;
}
export const AddDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
export const ToHHMMSS = (s) => {
    var hours = Math.floor(s / 3600);
    var minutes = Math.floor(s / 60) % 60;
    var seconds = s % 60;
    var fformat = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + parseInt(seconds).toString().padStart(2, '0');
    return fformat;
}
export const ToHHMM = (m) => {
    var hours = Math.floor(m / 60);
    var minutes = Math.floor(m) % 60;
    var fformat = hours.toString() + 'h ' + minutes.toString() + 'm';
    return fformat;
}

export const BrowserName = () => {
    const agent = navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf("edge") > -1: return "MS Edge";
        case agent.indexOf("edg/") > -1: return "MS Edge";
        case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
        case agent.indexOf("trident") > -1: return "MS IE";
        case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
        case agent.indexOf("safari") > -1: return "Safari";
        default: return "unknown";
    }
}
export const BrowserDevice = () => {
    const agent = navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf("android") > -1: return "Android";
        case agent.indexOf("iPhone") > -1: return "iphone";
        case agent.indexOf("ipad") > -1: return "iPad";
        case agent.indexOf("windows phone") > -1: return "Window Phone";

        case agent.indexOf("windows nt 10") > -1: return "Windows 10";
        case agent.indexOf("windows nt 6") > -1: return "Windows";

        case agent.indexOf("mac") > -1: return "iMac";
        case agent.indexOf("x11") > -1: return "Unix";
        case agent.indexOf("linux") > -1: return "Linux";

        default: return "unknown";
    }
}

