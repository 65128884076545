import { loginApiRequest } from "../authConfig";
import { msalInstance } from "../../index";
//this function used to call only get apis.
export async function GetAPI(url, accessToken,signal=null) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account
        });
        accessToken = response.accessToken;
    }
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.set("Authorization", bearer);
    headers.set('Accept', 'application/json, text/plain');
    headers.set('Content-Type', 'application/json;charset=UTF-8');

    const options = { method: "GET", headers: headers,signal};

    return fetch(url, options)
        .then((response, reject) => {
            if (response.ok) {
                return response.json()
            }
            else {
                console.log(response);//track error
                if (response.status === 400){
                        return response.json().then((errorObj) =>{                          
                            return (Promise.reject({ error: response.status, msg: errorObj.message }))
                        }
                    );
                }
                else{
                    if(response.status === 403){
                        return (Promise.reject({ error: response.status, msg: 'You do not have permission!!'}))
                    }
                    else{                      
                        return response.json().then((errorObj) =>{                        
                            return (Promise.reject({ error: response.status, msg: errorObj.message }))
                        }
                    ); 
                    }
                }
            }
        }
        )
        .catch(error => {
            return error;
        });
}