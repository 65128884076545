import React, {useEffect } from 'react';
import { useMsal,useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from '@azure/msal-browser';
import { msalConfig } from "../../Utilities/authConfig";
import {LoadingClock} from "../../layout/Loader/LoadingClock"
export const SignIn = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(msalConfig);
    }
  },[]);
 return (<>
 { <><LoadingClock></LoadingClock></>}
 </>)
};