import React, { Suspense, useEffect, useRef, useState } from 'react';
import {  Paper, Grid, CardContent,Card,FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import {UcPulseReport} from './Reports/UcPulseReport';
import {LoadingSpinner} from "../layout/Loader/LoadingSpinner"
import { useErrorBoundary } from 'react-error-boundary';
import { GetAPI } from '../Utilities/API/GetAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import '../App.css'

export function Report() {

    const [reports, setReportsValue] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [taskvalue, setTaskvalue] = useState("");
    const handleError = useErrorBoundary();
    useEffect(() => {
        setIsLoading(true);
        GetAPI(ApiURLs.EmployeeAccess + "?module=Reports").then((response) => {
            //set loading to false
            setIsLoading(false);
            if (response.error) {
                //through exception here
                handleError("Server Error :" + response.error);
            }
            else {
                setReportsValue(response);
            }
        }).catch((e) => { console.log(e); handleError(e); });;

    }, []);
    const handleTaskValue = (event) => {
        setTaskvalue(event.target.value);
    };

    return (
            <>
            {
                    isLoading ? <LoadingSpinner /> :
                        <Card variant= "outlined" style={{ marginTop: 10 }} >
                            <CardContent style={{ justifyContent: 'center', paddingTop: 10, paddingBottom: 8, paddingLeft: 10 }}>
                                <FormControl sx={{ width: 400 }} size="small">
                                    <InputLabel className="App-FontColor"  id="demo-simple-select-label">Select Reports</InputLabel>
                                    <Select style={{ fontSize: 14 }} labelId="demo-simple-select-label" id="demo-simple-select" label="Select Reports" value={taskvalue} onChange={handleTaskValue} >
                                        <MenuItem style={{ fontSize: 13, padding: 2, paddingLeft: 6 }} value={0}>--Select Reports--</MenuItem>
                                        {
                                            reports.map((row, index) => {
                                                return (
                                                    <MenuItem  className="App-FontColor"  style={{ fontSize: 13, padding: 2, paddingLeft: 6 }} key={index} value={row.feature}>{row.featureDisplay}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </CardContent>
                         </Card>
                }
                {taskvalue === "UcPulseReport" && <UcPulseReport />}
    
            </>
    
        );

}
