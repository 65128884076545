import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import '../../App.css'
export default function Copyright(props) {
    return (
        <>
          <div style={{ marginTop: 'auto' }}>
            {/* Your existing code */}
            <Typography className="App-FontColor" variant="body2"  align="center" {...props}>
              {'Copyright © '}
              <Link  href={window.location.origin}>
                Pulsemeter
              </Link>{' '}
              {new Date().getFullYear()}
              {'.'}
            </Typography>
          </div>
        </>
      );
  }