import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_ClientId, // Client ID 
      authority: process.env.REACT_APP_Authority, // Tenant ID of the React.JS App Registration
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl:false
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: string, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
             default:
                console.info(message)

            }
          },
        },
      },
    };
  
// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [process.env.REACT_APP_Scope]
};

export const silentRequest = {
  scopes: ["User.ReadBasic.All"],
};