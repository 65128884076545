import AttachFileSharpIcon from '@mui/icons-material/AttachFileSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { loginApiRequest } from '../../Utilities/authConfig';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import { msalInstance } from '../..';
import { Button, Divider,FormControl, Select, MenuItem,Card, InputLabel,Typography,CardContent, Grid, Tooltip,Dialog,IconButton,TextField,Box,InputAdornment, Hidden} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useRef, useState } from 'react';
import  UcAddNewBusinessHeadMapping  from './UcAddNewBusinessHeadMapping';
import EditIcon from '@mui/icons-material/Edit';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { GetDownloadAPI } from '../../Utilities/API/GetDownloadAPI';
import {PutAPI} from '../../Utilities/API/PutAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import Switch from '@mui/material/Switch';
import { PostAPIFile } from '../../Utilities/API/PostAPIFile';
import  UcBusinessHeadUploadResult  from './UcBusinessHeadUploadResult';
import '../../App.css'
import  UcBusinessHeadUpdate  from './UcBusinessHeadUpdate';
import { UcGeneratedReports } from '../../layout/UcGeneratedReports';

export function UcBusinessHeadMapping() {
    const [openrequest, setopenrequest] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [searchtext, setsearchtext] = useState('');
    const [isDatafound, setIsDatafound] = React.useState(true);
    const [loading, setLoading] = useState(false);
    const [downloadloading, setDownloadLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [listOfResultSet, setListOfResultSet] = useState([]);
    const [openUcGeneratedReportsDialog, setopenUcGeneratedReportsDialog] = useState(false);
    const previousController = useRef();
    const [pageState, setPageState] = useState({
      isLoading: false,
      data: [],
      total: 0,
      page: 0,
      pageSize: 10
    })

    const [parameters, setparameters] =  useState(
      {
        id:0,
        countryId:'',
        divisionId:'',       
        organizationalUnitId:'',
        headEmployeeId:'',
        headEmployeeName:'',
        active:0
      }
    );
   
    useEffect(() => {
      if (searchtext.length >= 3) {
        getBussinessUnitHeadDetailsByPage({ isSearch: false, isExport: false });
      }
      else if (searchtext.length == '' || searchtext.length == 0 || searchtext.length == null) {
        getBussinessUnitHeadDetailsByPage({ isSearch: true, isExport: false });
      }
    }, [searchtext])
    const handleChange = (e) => {
      setsearchtext(e.target.value);
    }
    useEffect(() => {
      setIsDatafound(true)
      getBussinessUnitHeadDetailsByPage({ isSearch: true, isExport: false });
    }, [pageState.page, pageState.pageSize]);

    const getBussinessUnitHeadDetailsByPage = ({ isSearch, isExport }) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;


    setLoading(true);
    setPageState(old => ({ ...old, isLoading: true }))
    if (IsNullOrEmptyOrUndefined(searchtext) === false && isSearch != true) {
      setPageState(old => ({ ...old, page: 0, pageSize: 10 }));
    }
    var parameters = { searchTerm: searchtext, pageSize: pageState.pageSize, pageNum: pageState.page===0?1:(pageState.page+1) };
    if (isExport) {
      parameters.exportData = true;
    }
    PostAPI(ApiURLs.GetBusinessUnitHeadDetailsByPage, parameters, null, signal).then((response) => {
      if (IsNullOrEmptyOrUndefined(response.error) === false) {
        if (response.error === 404) {
          CustomAlert('Unauthrize error, try to login again', 'error');
        } else {
          CustomAlert(response.msg, 'error');
        }
      }
      else {
        setPageState(old => ({ ...old, isLoading: false, data: response, total: response.length > 0 ? response[0].totalCount : 0 }))
        if (response.length > 0) {
          setIsDatafound(true);
        }
        else if (response.length === undefined) {
          setPageState(old => ({ ...old, isLoading: true }))
        }
        else {
          setIsDatafound(false);
        }
        if (isExport) {
          if(isDatafound === false){
              CustomAlert('No data found to Export','error')
          }
          else{
              setopenUcGeneratedReportsDialog(true);
          }
       }
      }
    })
      .catch((e) => {
        setLoading(false);
        setPageState(old => ({ ...old, isLoading: false, data: [], total: 0, page: 1, pageSize: 10 }));
        CustomAlert(e, 'error');
      });
  }
 
  const handleopenUpdate = (row) => { 
    setOpenUpdate(true);     
    setparameters({id:row.id,countryId:row.countryId,divisionId:row.divisionId,organizationalUnitId:row.organizationalUnitId,headEmployeeId:row.headEmployeeId,headEmployeeName:row.headEmployeeName,active:row.status});
  }

  const handleCloseUpdate = () => {
      setOpenUpdate(false);
      getBussinessUnitHeadDetailsByPage({ isSearch: true, isExport: false });
  }
  const handleClose = () => {
      setopenrequest(false);
      getBussinessUnitHeadDetailsByPage({ isSearch: true, isExport: false });    
  }
  const handleopen = () => {
      setopenrequest(true); 
  }

  async function handleDownload(accessToken) {
      setDownloadLoading(true);
      var url = ApiURLs.DownloadBUHeadMappingTemplate;

      try {
          if (!accessToken) {
              const account = msalInstance.getActiveAccount();
              if (!account) {
                  throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
              }
              const response = await msalInstance.acquireTokenSilent({
                  ...loginApiRequest,
                  account: account
              });
              accessToken = response.accessToken;
          }
          GetDownloadAPI(url, accessToken)
              .then((response) => {
                setDownloadLoading(false)

                  // Check if the response is a Blob
                  if (response instanceof Blob) {
                      // Create a Blob from the array buffer
                      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                      // Create a link element and trigger a click to start the download
                      const link = document.createElement('a');
                      link.href = URL.createObjectURL(blob);
                      link.download = 'BUHeads_Template.xlsx';
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                  } else {
                      CustomAlert('Invalid response format', 'error');
                  }
              })
              .catch((e) => {
                  setDownloadLoading(false)
                  CustomAlert(e, 'error');
              });
      } catch (error) {
          setDownloadLoading(false)
          console.error('Error in handleDownload:', error);
          CustomAlert('Error downloading template', 'error');
      }
  };
  const handleUploadClick = () => {
    if (selectedFile) {
      uploadTemplate(selectedFile)
    } else {
      CustomAlert('Please select a file', 'error')
    }
  }
  const uploadTemplate = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    var url = ApiURLs.UploadTemplate;
    PostAPIFile(url, formData).then((response) => {
      setLoading(false);
      if (IsNullOrEmptyOrUndefined(response.error) === false) {
        if (response.error === 400 && response.result !== null) {
          CustomAlert(response.msg, 'error');
          setListOfResultSet(response.result);
          setOpenResult(true);
          setSelectedFile(null);           
        }
        else if (response.error === 400 && response.result === null) {
          CustomAlert(response.msg, 'error');
          setSelectedFile(null);
        }
    }
    else {
        CustomAlert(response.message, 'success');
        setSelectedFile(null);
        getBussinessUnitHeadDetailsByPage();  
    }
  })
    .catch((e) => {
        setLoading(false);
        CustomAlert(e, 'error');
    });
  }
  const handleButtonClick = () => {
    document.getElementById('file-upload').click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      event.target.value = null;
      setSelectedFile(file);
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        CustomAlert('File size exceeds the allowed limit of 5 MB.', 'error')
        setSelectedFile(null);
      }
    }
  };
  const GetReportAndExport = () => {
    setLoading(true);
    getBussinessUnitHeadDetailsByPage({ isSearch: false, isExport: true });
  };
  const hideUcGeneratedReportsDialog = () => {
      setopenUcGeneratedReportsDialog(false);
  };
  
  const columns = [
      //{ field: 'id', headerName: 'Id',  hide:true},
      {
          field: 'country', headerName: 'Country', headerAlign: 'left', align: 'left', sortable: false, width: 150,
          renderCell: (cell) => {
            return (<Tooltip title={cell.row.country}>
               <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                {IsNullOrEmptyOrUndefined(cell.row.country) === true ? "" : cell.row.country}
              </Typography></Tooltip>);
          },
      },
      {
        field: 'division', headerName: 'Division', headerAlign: 'left', align: 'left', sortable: false, width: 200,
        renderCell: (cell) => {
          return (<Tooltip title={cell.row.division}>
             <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
              {IsNullOrEmptyOrUndefined(cell.row.division) === true ? "" : cell.row.division}
              </Typography></Tooltip>);
        },
      },
      {
        field: 'account', headerName: 'Account', headerAlign: 'left', align: 'left', sortable: false, width: 150,
        renderCell: (cell) => {
          return (<Tooltip title={cell.row.account}>
            <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
              {IsNullOrEmptyOrUndefined(cell.row.account) === true ? "" : cell.row.account}
            </Typography></Tooltip>);
        },
      },
      {
        field: 'businessUnit', headerName: 'Business Unit', headerAlign: 'left', align: 'left', sortable: false, width: 200,
        renderCell: (cell) => {
          return (<Tooltip title={cell.row.businessUnit}>
             <Typography style={{ fontSize: 13, whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
              {IsNullOrEmptyOrUndefined(cell.row.businessUnit) === true ? "" : cell.row.businessUnit}
              </Typography></Tooltip>);
        },
      },
      {
          field: 'headEmployeeId', headerName: 'Head Employee Id', headerAlign: 'left', align: 'left', sortable: false, width: 150,
          renderCell: (cell) => {
            return (<Tooltip title={cell.row.headEmployeeId}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.headEmployeeId) === true ? "" : cell.row.headEmployeeId}</Typography></Tooltip>);
          },
        },
        {
          field: 'headEmployeeName', headerName: 'Head Employee Name', headerAlign: 'left', align: 'left', sortable: false, width: 200,
          renderCell: (cell) => {
            return (<Tooltip title={cell.row.headEmployeeName}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.headEmployeeName) === true ? "" : cell.row.headEmployeeName}</Typography></Tooltip>);
          },
        },
        {
          field: 'status', headerName: 'Status', headerAlign: 'left', align: 'left', sortable: false, width: 100,
          renderCell: (cell) => {
            return (<Tooltip title={cell.row.status}><Typography style={{ fontSize: 13 }}>{IsNullOrEmptyOrUndefined(cell.row.status) === true ? "" : cell.row.status}</Typography></Tooltip>);
          },
        },
        {
            field: 'action', headerName: 'Action', headerAlign: 'left', align: 'left', sortable: false, width: 100,
            renderCell: (cell) => {
            return (<Tooltip title="Edit"><IconButton color='primary' style={{ padding: 2}} onClick={() => handleopenUpdate(cell.row)}><EditIcon className="App-Icons" fontSize="small" /></IconButton></Tooltip>);
            },
        },      
  ] 

    return (
        <>
        <Card variant="outlined" style={{ marginTop: 10 }} >
            <CardContent style={{ display: 'flex', alignItems: 'center', paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>                 
                    <div>
                      <label htmlFor="file-upload">
                      <Tooltip title={selectedFile ? selectedFile.name : 'Choose File'} enterDelay={500}>
                      <Button                
                      component="label"
                      variant="outlined"
                      startIcon={<AttachFileSharpIcon className="App-Icons" />}
                      style={{ color: 'silver', justifyContent: 'left', width: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      onClick={handleButtonClick}
                      >
                      {selectedFile ? selectedFile.name : 'Choose File'}
                      </Button>
                      </Tooltip>
                      </label>
                      <input
                      id="file-upload"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      />
                    </div>
                    <LoadingButton disableElevation className='App-Button' variant="contained" style={{ textTransform: 'none', fontSize: 12, width: 120, marginLeft: 10 }} startIcon={<CloudUploadIcon  />} onClick={() => handleUploadClick()}> Upload File </LoadingButton>
                    <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 12, width: 180, marginLeft: 10 }} onClick={() => handleDownload()} disabled={downloadloading} startIcon={<FileDownloadIcon  style ={{fontSize: 16}}/>}>
                        {downloadloading ? 'Downloading...' : 'Download Template'}                      
                    </Button>
                    <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 12, width: 100, marginLeft: 10}} onClick={handleopen} startIcon={<AddIcon style ={{fontSize: 16,marginRight: -4}} />}>Add New</Button>   
                    <Button variant="contained" className='App-Button' style={{ textTransform: 'none', fontSize: 12, width: 150, marginLeft: 10}} onClick={GetReportAndExport}>Export to Excel</Button>         
                </CardContent>
        </Card>
        <Card variant='outlined' style={{ marginTop: 5, marginBottom: 15, minHeight: 300 }}>              
            <div style={{ paddingLeft: '80%', paddingBottom: '1%' }}>
                <TextField style={{ width: '80%' }}
                    placeholder='Search...'
                    value={searchtext}
                    onChange={handleChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className="App-Icons"/>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>
            {(pageState.data.length > 0 || pageState.data.length === undefined) &&
                <Box sx={{ height: 320, width: '100%' }}>
                     <DataGrid
                      getRowId={(row) => row.id}
                      disableColumnMenu
                      rows={pageState.data}
                      rowCount={pageState.total}
                      loading={pageState.isLoading}
                      pageSizeOptions={[10, 30, 50, 70, 100]}
                      pagination                    
                      paginationMode="server"
                      onPaginationModelChange={(e) => {
                        setPageState(old => ({ ...old, page: e.page, pageSize:e.pageSize}))
                      }}
                      paginationModel={pageState}
                      columns={columns}
                      getRowHeight={() => 'auto'} headerHeight={31}
                      className="App-Icons"
                      sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: 0 } }}
                    />
                </Box>
            }
            {isDatafound === false &&
              <Box sx={{ height: 320, width: '100%', paddingTop: 20 }}>
                  <div sx={{ fontSize: 13, align: 'center' }}>
                      <center>No record found</center>
                  </div>
              </Box>
            }              
        </Card>
        <Dialog
            open={openrequest}
            onClose={handleClose}
            PaperProps={{
                style: {
                height: '300px', 
                width: '800px', 
                },
            }}
            style={{ alignSelf: 'center' }}
            >
            <UcAddNewBusinessHeadMapping
                open={openrequest}
                onClose={handleClose}
            />
        </Dialog>
        <Dialog  open={openUpdate} 
            PaperProps={{
                style: {
                height: '300px',
                width: '800px',
                },
            }}
            style={{ alignSelf: 'center' }}>  
             <UcBusinessHeadUpdate
                open={openUpdate}
                onClose={handleCloseUpdate}
                data={parameters}
            />       
        </Dialog>
        <Dialog open={openResult} fullWidth={true} onClose={() => setOpenResult(false)} maxWidth={'md'} repositionOnUpdate={false} style={{ padding: 0 }}>
            <UcBusinessHeadUploadResult resultSet={listOfResultSet} open={openResult} onClose={() => setOpenResult(false)} />
        </Dialog>
        <Dialog open={openUcGeneratedReportsDialog} fullWidth={true} onClose={hideUcGeneratedReportsDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEscapeKeyDown repositionOnUpdate={false} style={{ padding: 0 }} maxWidth={'md'}>
            <UcGeneratedReports onClose={hideUcGeneratedReportsDialog} />
        </Dialog>
        </>
    )
}
