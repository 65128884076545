import { SignOutButton } from "./Signout";
import React from 'react';
import { Paper, Grid, Card, Typography } from '@mui/material';
import Thinking from '../../AppImages/Thinking.gif';

export const Unauthorised = (props) => {
    
    return (
        <>
           <Card variant="outlined" style={{ marginTop: 10, width: '100%', height: '84vh' }}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <img src={Thinking} alt="Thinking" />
                    </Grid>
                    <Grid item>
                        <h2>Oops, You don't have permission to access this page.</h2>
                        {/* <Typography style={{fontSize: 30 }}>Oops, You don't have permission to access this page.</Typography> */}
                    </Grid>
                    <Grid item>
                        <p>Click on Sign In button to login with different account.</p>
                        <Grid container justifyContent="center">
                            <SignOutButton />
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
  };