import { Grid, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React from 'react';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
export function UcDatesFilter(props) {

    const handleFromChange = (newValue) => {
        if (IsNullOrEmptyOrUndefined(newValue) === false && dayjs(newValue).isValid()) {
            props.setFromDate(newValue);
            if (new Date(newValue) > new Date(props.toDate)) {
                props.setToDate(newValue);
            }
            props.setFromDateError(0);
        }
        else {
            //props.setFromDate("");
            if (props.datesRequired && IsNullOrEmptyOrUndefined(newValue)) {
                props.setFromDateError(1); // Error code for required date
            } else {
                props.setFromDateError(1); // Error code for invalid date
            }
        }
    };

    const handleToChange = (newValue) => {
        if (IsNullOrEmptyOrUndefined(newValue) === false && dayjs(newValue).isValid()) {
            props.setToDate(newValue);
            if (new Date(newValue) < new Date(props.fromDate)) {
                props.setFromDate(newValue)
            }
            props.setToDateError(0);
        }
        else {
            //props.setToDate("");
            if (props.datesRequired && IsNullOrEmptyOrUndefined(newValue)) {
                props.setToDateError(1); // Error code for required date
            } else {
                props.setToDateError(1); // Error code for invalid date
            }
        }
    };

    return (
        <>
            <Grid container spacing={1} style={{ paddingBottom: 5, paddingTop: 10, marginRight: 0, marginLeft: 0 }}>
                <Grid item xs={6} style={{ paddingLeft: 0 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{ textField: { size: 'small' } }} 
                            label={`Start Date${props.datesRequired ? ' *' : ''}`}
                            minDate={IsNullOrEmptyOrUndefined(props.minfromdate) === true ? dayjs(new Date()).add(-20, 'years') : props.minfromdate}
                            maxDate={props.disableFutureDates ? dayjs() : (IsNullOrEmptyOrUndefined(props.maxfromdate) ? dayjs().add(20, 'years') : props.maxfromdate)}
                            format="DD MMM YYYY" value={props.fromDate}
                            onChange={handleFromChange} renderInput={(params) => <TextField error={props.fromDateError} {...params} sx={{ width: '100%' }} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker slotProps={{ textField: { size: 'small' } }} 
                            label={`End Date${props.datesRequired ? ' *' : ''}`}
                            minDate={IsNullOrEmptyOrUndefined(props.mintodate) === true ? dayjs(new Date()).add(-20, 'years') : props.mintodate}
                            maxDate={props.disableFutureDates ? dayjs() : IsNullOrEmptyOrUndefined(props.maxtomdate) === true ? dayjs(new Date()).add(20, 'years') : props.maxtomdate} format="DD MMM YYYY" value={props.toDate}
                            onChange={handleToChange} renderInput={(params) => <TextField error={props.toDateError} {...params} sx={{ width: '100%' }} size='small' />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </>
    );
}