import { Add_User_Token } from "../actionTypes";
const initialState = {
    token: "",datetime: new Date()
  };
const tokenReducer = (state = initialState, action) => {
  const newState = { ...state };  
  switch (action.type) {
      case Add_User_Token:
        newState.token = action.token;
        return newState;
      default:
        return state;
    }
  };

  export default tokenReducer;
  // As of now we are not using this reducer, but we need to create more reducers to store other values.
  // we can use this reducer as example to create and use more reducer