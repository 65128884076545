// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken,onMessage } from "firebase/messaging";
import { PostAPI } from "../Utilities/API/PostAPI"
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { CustomAlert } from '../Utilities/CustomAlert';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_apiKey,
    authDomain: process.env.REACT_APP_FB_authDomain,
    projectId: process.env.REACT_APP_FB_projectId,
    storageBucket: process.env.REACT_APP_FB_storageBucket,
    messagingSenderId: process.env.REACT_APP_FB_messagingSenderId,
    appId: process.env.REACT_APP_FB_appId,
    measurementId: process.env.REACT_APP_FB_measurementId
};


const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
    try {
        // Request permission for notifications
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            // Get the FCM token
            const token = await getToken(messaging,{ vapidKey: process.env.REACT_APP_FB_vapidKey });
            console.log('FCM Token:', token);
            PostAPI(ApiURLs.UserDetails_DeviceToken, { devicetoken: token }).then((response) => {
                console.log(response);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            console.log('Notification permission denied.');
        }
        // Handle foreground notifications
        onMessage(messaging, (payload) => {
            console.log('Foreground Message:', payload);
            CustomAlert(payload.notification.body, 'Info');
            // Handle the notification or update your UI
        });
    } catch (error) {
        console.error('Error setting up notifications:', error);
    }
};
export { messaging, setupNotifications };

