
import React, { Suspense, useEffect } from 'react';
import { CircularProgress, Fab } from '@mui/material';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Main from './ui-component/Main';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from "react-redux";
import store from './State/Store';
import { SignIn } from '../src/ui-component/Login/SignIn'
import { ErrorBoundary } from 'react-error-boundary';

const theme = createTheme({
  typography: {
    fontFamily: "Helvetica, Arial"
  }
});
function App() {  
 return (
    <>
      <AuthenticatedTemplate>
        <Provider store={store}>
          <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', position: 'absolute', minWidth: '100%', left: 0, top: '46%' }}><Fab disabled={true} style={{ backgroundColor: '#fff', boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(75, 22, 22, 0.12)' }}>{<CircularProgress size={35} style={{ color: '#1976d2' }} />}</Fab></div>}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
              <ThemeProvider theme={theme}>
                <Main  ></Main>
              </ThemeProvider>
            </ErrorBoundary>
          </Suspense>
        </Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignIn />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;


function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
const myErrorHandler = (error, stack) => {
  // Call application insight object and pass error log
  // E.g. log to an error logging client here
}