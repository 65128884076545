import React, { Component } from 'react'
import { Container } from '@mui/material'
import { NavMenu } from './NavMenu';
import Copyright  from '../layout/Footer/Index';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <NavMenu />
                <Container style={{ flex: '1',maxWidth: 'inherit', paddingLeft: 5, paddingRight: 5 }}>
                    {this.props.children}
                </Container>
                <Copyright></Copyright>
            </div>
        );
    }
}
