import { loginApiRequest } from "../authConfig";
import { msalInstance } from "../../index";

export async function GetDownloadAPI(url,filename, accessToken, signal = null) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.set("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers,
        signal
    };

    return fetch(url, options)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                console.log(response); // Track error
                return Promise.reject({ error: response.status, msg: response.statusText });
            }
        })
        .catch(error => {
            return error;
        });
}
