import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SentimentSatisfiedOutlined, KeyboardArrowDown, Logout, FileDownloadOutlined } from '@mui/icons-material';
import { Button,Tooltip, AppBar, Toolbar, Box, Menu, MenuItem, Badge,ListItemIcon, ListItemText, Divider, IconButton, Typography,Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import '..//..//src/App.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { msalInstance } from "../index";
import { ToastContainer } from 'react-toastify';
import { connect, useSelector } from 'react-redux'
import AddReactionIcon from '@mui/icons-material/AddReaction';
import Pulsemeterlogo from '../layout/PulseMeterLogo.png'
import { ProxyLogin } from '../ui-component/Login/ProxyLogin';
import ProfilePicture from '../layout/ProfilePicture';
import AllowNotification from '../layout/AllowNotification'
import { UcGeneratedReports } from '../layout/UcGeneratedReports';
import GroupsIcon from '@mui/icons-material/Groups';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Notification from '../ui-component/HuddleHome/Notification';



export function NavMenu(props) {
    const [openUcGeneratedReportsDialog, setOpenUcGeneratedReportsDialog] = React.useState(false);
    const [openUnread, setOpenUnread] = React.useState(false);

    const userinfo = useSelector((state) => state.userDetailsReducer.userDetails);
    var admin = userinfo.groups.filter(o => o === 'Admin' || o === 'HR');
    const account =  userinfo.firstname + " " + userinfo.lastname;
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const CheckProxyLogin = () => {
        const IsproxyUser = userinfo.allowProxy;
        return IsproxyUser == 1 ? <ProxyLogin /> : <div></div>; 
    }
    

    const handleClose = (btnClicked) => {

        switch (btnClicked) {
            case 'Pulsemeter':
                navigate('/Dashboard'); break;
            case 'Feedback':
                navigate('/Home',{
                    state: { flag: true }}); break;
            case 'Actions':
                navigate('/Actions'); break;
            case 'Report':
                navigate('/Report'); break;
            case 'Logout':
                msalInstance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                  });
                break;
            // case 'HuddleHome':
            //     navigate('/Home',{
            //         state: { flag: false }}); break;
            default:
                setAnchorEl(null); break;
        }
        setAnchorEl(null);
    };

    const handleExportReport = () => {
        setOpenUcGeneratedReportsDialog(true);
    }

    const hideUcGeneratedReportsDialog = () => {
        setOpenUcGeneratedReportsDialog(false);
    }
  
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="static" elevation={0} sx={{ backgroundColor: 'white ' }}>
                    <Toolbar style={{ minHeight: 55 }}>
                        {/* <SentimentSatisfiedOutlined sx={{ fontSize: 35, color: 'orange' }} /> */}
                        <Link to="/Home" style={{ textDecoration: 'none' }}>
                        <img src={Pulsemeterlogo} alt='Logo'
                         style={{ 
                            marginTop: '4px',
                            width: '180px', 
                            height: '55px', 
                            transition: 'transform 0.3s', // Add transition for smooth zoom-in
                            transform: 'scale(1.3)', // Default scale
                        }} 
                        ></img>
                        {/* <Typography style={{ paddingLeft: 5 }} variant="h6" component="div">PulseMeter</Typography> */}

                        </Link>
                        <div style={{ flexGrow: 1 }}></div>
                        
                        <div>
                            <CheckProxyLogin/>
                        </div>
                        <AllowNotification></AllowNotification>
                        {/* <div style={{ marginRight: 15 }}></div>
                          <Notification/>
                        
                        <div style={{ marginRight: 15 }}></div>
                        <Tooltip title="Huddle">
                            <IconButton onClick={() => handleClose('HuddleHome')}><GroupsIcon className="App-Icons" sx={{ fontSize: '25px' }} /></IconButton>
                        </Tooltip> */}

                        {admin.length > 0 && <>
                            <div style={{ marginRight: 15 }}></div>
                            <Tooltip title="Downloads">
                            <IconButton onClick={handleExportReport}><FileDownloadOutlined className="App-Icons" /></IconButton>
                            </Tooltip>
                            </>
                        }
                        <Dialog open={openUcGeneratedReportsDialog} fullWidth={true} maxWidth={'md'} onClose={hideUcGeneratedReportsDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEscapeKeyDown repositionOnUpdate={false} style={{ padding: 0 }}>
                            <UcGeneratedReports onClose={hideUcGeneratedReportsDialog} />
                        </Dialog>
                        <div style={{ marginRight: 15 }}></div>
                        <ProfilePicture />
                        
                        <Button disableElevation style={{ textTransform: 'none', fontSize: 15, fontWeight: 600 }} onClick={handleClick} endIcon={<KeyboardArrowDown className="App-Icons" />}>
                            <Typography className="App-FontColor" sx={{ flexGrow: 1 }}>
                                Hi, {account}
                            </Typography>
                        </Button>
                    </Toolbar>
                    <Divider />

                </AppBar>
            </Box>

            <Menu elevation={0} sx={{ fontSize: 13, '& .MuiPaper-root': { border: '1px solid #d9d9d9' } }} anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                {admin.length > 0 &&
                <MenuItem onClick={() => handleClose('Feedback')}>
                    <ListItemIcon><AddReactionIcon fontSize="small" className="App-Icons" /></ListItemIcon>
                    <ListItemText className="App-FontColor">Feedback</ListItemText>
                </MenuItem>
                }
                {admin.length > 0 &&
                    <MenuItem onClick={() => handleClose('Pulsemeter')}>
                        <ListItemIcon>
                            <DashboardIcon fontSize="small" className="App-Icons" />
                        </ListItemIcon>
                        <ListItemText className="App-FontColor">Pulsemeter</ListItemText>
                    </MenuItem>
                }
                {/* <MenuItem onClick={() => handleClose('Huddle Analytics')}>
                    <ListItemIcon>
                        <AssessmentIcon fontSize="small" className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Huddle Analytics</ListItemText>
                </MenuItem> */}
                {admin.length > 0 &&
                <MenuItem onClick={() => handleClose('Report')}>
                    <ListItemIcon>
                        <SummarizeIcon fontSize="small"  className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Report</ListItemText>
                </MenuItem>
                }
                {admin.length > 0 &&
                    <MenuItem onClick={() => handleClose('Actions')}>
                        <ListItemIcon>
                            <PendingActionsIcon fontSize="small" className="App-Icons" />
                        </ListItemIcon>
                        <ListItemText className="App-FontColor">Actions</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={() => handleClose('Logout')}>
                    <ListItemIcon>
                        <Logout fontSize="small" className="App-Icons" />
                    </ListItemIcon>
                    <ListItemText className="App-FontColor">Logout</ListItemText>
                </MenuItem>
            </Menu>
            <ToastContainer />
        </>
    );
}