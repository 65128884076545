import React, { useState, useEffect, useRef } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { Card, CardContent, Typography, Tooltip, IconButton, Button, Dialog} from '@mui/material';
import { PostAPI } from '../Utilities/API/PostAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';
import { useReactToPrint } from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import Excel from '../AppImages/Excel.png';
import { UcGeneratedReports } from '../layout/UcGeneratedReports';


export default function DashboardTenurewiseBarChart(props) {
  const previousController = useRef(null);
 
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [tenureFeedbackCount, setTenureFeedbackCount] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openUcGeneratedReportsDialog, setopenUcGeneratedReportsDialog] = useState(false);

    var countryId = props.filterModel.selectedCountry;
    var divisionId = props.filterModel.selectedDivision;
    var accountId = props.filterModel.selectedAccount;
    var organizationalUnitId = props.filterModel.selectedBusinessUnit;
    var buHeadIds = props.filterModel.selectedBusinessHead;
    var bands = props.filterModel.selectedBand;
    var startDate = props.filterModel.startDate;
    var endDate = props.filterModel.endDate;

    useEffect(() => {
        if(countryId !== null || divisionId !== null || accountId !== null || organizationalUnitId !== null) {
          getFeedbackCountByTenure();
        }
    }, [props.filterModel]);

      const getFeedbackCountByTenure = () => {
        if (previousController.current) {
          previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, buHeadIds, bands, startDate, endDate};
        PostAPI(ApiURLs.FeedbackCountByTenure, parameters, null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
              if (response.length > 0) {
                setTenureFeedbackCount(response);
              } else {
                setTenureFeedbackCount([]);
              }
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };

      const chartSetting = {
        width: 600,
        height: 250,
        sx: {
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
          },
        },
      };
      
      const transformedData = tenureFeedbackCount.map(item => {
        const dataObject = { tenure: item.tenure };
        item.feedbacks.forEach(feedback => {
          dataObject[feedback.feedback.toLowerCase()] = feedback.totalCount;
        });
        return dataObject;
      });
      
      // Extract unique feedback types
      const feedbackTypes = Array.from(
        new Set(tenureFeedbackCount.flatMap(item => item.feedbacks.map(feedback => feedback.feedback.toLowerCase())))
      );
      
      // Create series configuration dynamically
      const series = feedbackTypes.map(type => ({
        dataKey: type,
        label: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize the first letter
      }));
      const getEmployeeFeedbackDetails = (Export) => {
        if (previousController.current) {
            previousController.current.abort();
          }
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, buHeadIds, bands, startDate, endDate};
        if (Export) {
            parameters.exportData = true;
            parameters.fileName =  "Pulse by Tenure - " + props.filterModel.selectedName; 
        }
        PostAPI(ApiURLs.EmployeeFeedbackDetails, parameters,null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                if (Export) {
                    setopenUcGeneratedReportsDialog(true);   
                }
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const GetReportAndExport = () => {
        setLoading(true);
        getEmployeeFeedbackDetails(true);
    };
    const hideUcGeneratedReportsDialog = () => {
        setopenUcGeneratedReportsDialog(false);
    };

    return (
      <>
      <Card variant="outlined" sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} ref={componentRef}>
          <Typography  className='App-FontColor' style={{fontSize:'20px', margin: '2%',position: 'relative' }}>
              Pulse by Tenure     
          <IconButton onClick={handlePrint} color="primary" className="no-print"
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
            }}>
          <Tooltip title="Click to print">
            <PrintIcon  className='App-Icons' fontSize="small" sx={{ fontSize: '18px' }}/>
          </Tooltip>
          </IconButton>
          <IconButton onClick={GetReportAndExport} color="primary"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 25,
                    }}>
                  <Tooltip title="Export to excel">
                    <img src={Excel} alt="Excel" style={{ width: '18px', height: '18px' }} />
                  </Tooltip>
          </IconButton>
          </Typography>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>            
                {loading ? (
                     <CircularProgress />
                ) :  (
                <BarChart
                    dataset={transformedData}
                    xAxis={[{ scaleType: 'band', dataKey: 'tenure' }]}
                    series={series}
                    {...chartSetting}
                    margin={{ right: 20, left: 60, }}
                    />
                )}
            </CardContent>
            <Typography style={{fontStyle:'italic', fontSize:'12px', padding: '8px', position:'relative'}}>
                Note: Graph is based on the total responses received.
            </Typography>
            
        </Card>
        <Dialog open={openUcGeneratedReportsDialog} fullWidth={true} onClose={hideUcGeneratedReportsDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEscapeKeyDown repositionOnUpdate={false} style={{ padding: 0 }} maxWidth={'md'}>
        <UcGeneratedReports onClose={hideUcGeneratedReportsDialog} />
     </Dialog>
     </>
    );
}