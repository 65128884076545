import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Divider, Typography,IconButton, Tooltip, Button, Dialog } from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { PostAPI } from '../Utilities/API/PostAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';
import { useReactToPrint } from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import Excel from '../AppImages/Excel.png';
import { UcGeneratedReports } from '../layout/UcGeneratedReports';



export default function DashboardPieChart(props) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const previousController = useRef(null);
    const [feedbackCount, setFeedbackCount] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [openUcGeneratedReportsDialog, setopenUcGeneratedReportsDialog] = useState(false);

      
    const size = {
        width: 500,
        height: 250,
    };

    var countryId = props.filterModel.selectedCountry;
    var divisionId = props.filterModel.selectedDivision;
    var accountId = props.filterModel.selectedAccount;
    var organizationalUnitId = props.filterModel.selectedBusinessUnit;
    var buHeadIds = props.filterModel.selectedBusinessHead;
    var bands = props.filterModel.selectedBand;
    var startDate = props.filterModel.startDate;
    var endDate = props.filterModel.endDate;

    useEffect(() => {
        if(countryId !== null || divisionId !== null || accountId !== null || organizationalUnitId !== null) {
            getAllFeedbackCountDetails();
        }
    }, [props.filterModel]);

      const getAllFeedbackCountDetails = () => {
        if (previousController.current) {
            previousController.current.abort();
          }
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, buHeadIds, bands, startDate, endDate};
        PostAPI(ApiURLs.AllFeedbackCountDetails, parameters,null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                if (response.length > 0) {
                    setFeedbackCount(response);
                } else {
                    setFeedbackCount([]);
                }
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };

    const getEmployeeFeedbackDetails = (Export) => {
        if (previousController.current) {
            previousController.current.abort();
          }
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, buHeadIds, bands, startDate, endDate};
        if (Export) {
            parameters.exportData = true;
            parameters.fileName =  "Pulse by " + props.filterModel.selectedName; 
        }
        PostAPI(ApiURLs.EmployeeFeedbackDetails, parameters,null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                if (Export) {
                    setopenUcGeneratedReportsDialog(true);
                }
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };
    const GetReportAndExport = () => {
        setLoading(true);
        getEmployeeFeedbackDetails(true);
    };
    const hideUcGeneratedReportsDialog = () => {
        setopenUcGeneratedReportsDialog(false);
    };

    const isDataAvailable = Array.isArray(feedbackCount) && feedbackCount.some(item => item.totalCount > 0);

    return (   
        <>
        <Card variant="outlined" sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} ref={componentRef}>
            <Typography  className='App-FontColor' style={{fontSize:'20px', margin: '2%',position: 'relative' }}>
                    Pulse by {props.filterModel.selectedName}                  
                    <IconButton onClick={handlePrint} color="primary" className="no-print"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}>
                  <Tooltip title="Click to print">
                    <PrintIcon  className='App-Icons' fontSize="small" sx={{ fontSize: '18px' }}/>
                  </Tooltip>
                </IconButton>
                <IconButton onClick={GetReportAndExport} color="primary"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 25,
                    }}>
                  <Tooltip title="Export to excel">
                    <img src={Excel} alt="Excel" style={{ width: '18px', height: '18px' }} />
                  </Tooltip>
                </IconButton>
            </Typography>          
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>            
                {loading ? (
                     <CircularProgress />
                ) : isDataAvailable ? (
                <PieChart
                    series={[
                        {
                        arcLabel: (item) => `${item.value}`,
                        arcLabelMinAngle: 45,
                        data: feedbackCount.map(item => ({
                            label: item.feedback,
                            value: item.totalCount,
                          })),
                       
                        outerRadius: 100,
                        },
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                        },
                    }}
                    {...size}
                    />
                ) : (
                    <Typography style={{fontSize: '0.875rem' }}>
                         No data to display
                    </Typography>
                )}
                   
            </CardContent>
            <Typography style={{fontStyle:'italic', fontSize:'12px', padding: '8px' }}>
                Note: Graph is based on the total responses received.         
            </Typography>
        </Card>
      
      <Dialog open={openUcGeneratedReportsDialog} fullWidth={true} onClose={hideUcGeneratedReportsDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEscapeKeyDown repositionOnUpdate={false} style={{ padding: 0 }} maxWidth={'md'}>
        <UcGeneratedReports onClose={hideUcGeneratedReportsDialog} />
     </Dialog>
  </>
      
    );
}