import { PostAPI } from '../../Utilities/API/PostAPI';
import { DeleteAPI } from '../../Utilities/API/DeleteAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import React, { useEffect, useState,useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { Grid, Typography, TextField, Box,IconButton,Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import '../../../src/App.css';
export function ProxyLogin() {
    const [loading, setLoading] = useState(false);
    const [emp, setEmp] = useState('');
    const addProxyUser = () => {
        setLoading(true);
        var parameters = {proxyEmployeeId: emp}
        PostAPI(ApiURLs.ProxyUser, parameters).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                sessionStorage.clear();
                CustomAlert(response.message, 'success');    
                window.location.href = window.location.href;    
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    }
    const removeProxyUser = () => {
        setLoading(true);    
        DeleteAPI(ApiURLs.RemoveProxyUser).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                CustomAlert(response.message, 'success');
                window.location.href = window.location.href;                 
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    }
    const handleTextFieldChange = (event) => {
        setEmp(event.target.value);  
    }
    
    return(
        <>
        <Box display="flex" alignItems="center">
        <TextField id="outlined-basic" placeholder = "Proxy Id" variant="outlined"
        InputProps={{
            style: { height: 30, padding: '0 14px' },           
            inputProps: {
                maxLength: 8,
                style: {
                    textAlign: 'center'
                }
            }
        }}       
        sx={{ width: 150,'& .MuiOutlinedInput-root': {height: 30, '& .MuiInputBase-input': {padding: '0 14px',
            '&::placeholder': {
            fontFamily: 'Arial',
            fontSize: '14px', 
          },
        },},}}
        value={emp}
        onChange={handleTextFieldChange}      
        />
        
        <Box ml ={1} mr={1}>       
       <LoadingButton
            disableElevation
            
            variant="contained"
            style={{ textTransform: 'none', fontSize: 11, height: 30, width: 100 }}
            onClick={() => {
                if (emp.length === 8) {
                    addProxyUser();
                } else {
                    CustomAlert('EmployeeId must be 8 characters long','error');
                }
            }}
            loading={loading}    
            className='App-Button'      

        >
            Proxy Login
        </LoadingButton> 
            
        </Box>  
        <Tooltip title="Switch to Main User">
            <IconButton  size="large" edge="start"  aria-label="menu" onClick={removeProxyUser}>
                <ClearIcon className='App-Icons'/>
            </IconButton>
        </Tooltip>    
        </Box>
        </>
    )
}