import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Divider, Typography,IconButton, Tooltip, Dialog} from '@mui/material';
import { PostAPI } from '../Utilities/API/PostAPI';
import { ApiURLs } from '../Utilities/API/ApiURLs';
import { IsNullOrEmptyOrUndefined } from '../Utilities/Utilities';
import { CustomAlert } from '../Utilities/CustomAlert';
import CircularProgress from '@mui/material/CircularProgress';
import { useReactToPrint } from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import Excel from '../AppImages/Excel.png';
import { UcGeneratedReports } from '../layout/UcGeneratedReports';



export default function DashboardConsumptionChart(props) {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const previousController = useRef(null);
    const [ConsumptionPercentage, setConsumptionPercentage] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [openUcGeneratedReportsDialog, setopenUcGeneratedReportsDialog] = useState(false);

    var countryId = props.filterModel.selectedCountry;
    var divisionId = props.filterModel.selectedDivision;
    var accountId = props.filterModel.selectedAccount;
    var organizationalUnitId = props.filterModel.selectedBusinessUnit;
    var buHeadIds = props.filterModel.selectedBusinessHead;
    var bands = props.filterModel.selectedBand;
    var startDate = props.filterModel.startDate;
    var endDate = props.filterModel.endDate;

    useEffect(() => {
        if(countryId !== null || divisionId !== null || accountId !== null || organizationalUnitId !== null) {
            getConsumptionPercentage();
        }
    }, [props.filterModel]);

      const getConsumptionPercentage = (Export) => {
        if (previousController.current) {
            previousController.current.abort();
          }
          const controller = new AbortController();
          const signal = controller.signal;
          previousController.current = controller;
        setLoading(true);
        var parameters = {countryId, divisionId, accountId, organizationalUnitId, buHeadIds, bands, startDate, endDate};
        if (Export) {
            parameters.exportData = true;
            parameters.fileName =  "Average Consumption % - " + props.filterModel.selectedName; 
        }
        PostAPI(ApiURLs.ConsumptionPercentage, parameters,null, signal ).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert(response.msg, 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                if (response > 0) {
                    setConsumptionPercentage(response);
                } else {
                    setConsumptionPercentage(0);
                }
                if (Export) {                 
                    setopenUcGeneratedReportsDialog(true);
                }
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    };

    const GetReportAndExport = () => {
        setLoading(true);
        getConsumptionPercentage(true);
    };
    const hideUcGeneratedReportsDialog = () => {
        setopenUcGeneratedReportsDialog(false);
    };

    return (
        <>
        <Card variant="outlined" sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }} ref={componentRef}>
        <Typography  className='App-FontColor' style={{fontSize:'20px', margin: '2%',position: 'relative' }}>
                Average Consumption %               
                <IconButton onClick={handlePrint} color="primary" className="no-print"
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}>
              <Tooltip title="Click to print">
                <PrintIcon  className='App-Icons' fontSize="small" sx={{ fontSize: '18px' }}/>
              </Tooltip>
            </IconButton>
            <IconButton onClick={GetReportAndExport} color="primary"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 25,
                    }}>
                  <Tooltip title="Export to excel">
                    <img src={Excel} alt="Excel" style={{ width: '18px', height: '18px' }} />
                  </Tooltip>
            </IconButton>
        </Typography>          
        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}> 
                {loading ? (
                     <CircularProgress />
                ) :  (           
                <Gauge width={200} height={200} value={ConsumptionPercentage}
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 30,
                        }
                    })} 
                />
                )}
        </CardContent>
        <Typography style={{fontStyle:'italic', fontSize:'12px', padding: '8px' }}>
            Note: Avg Consumption % Formula = (Average Total response received/Average Total headcount)*100
        </Typography>
    </Card>
    <Dialog open={openUcGeneratedReportsDialog} fullWidth={true} onClose={hideUcGeneratedReportsDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableEscapeKeyDown repositionOnUpdate={false} style={{ padding: 0 }} maxWidth={'md'}>
        <UcGeneratedReports onClose={hideUcGeneratedReportsDialog} />
    </Dialog>
    </>
    );
}