
import { Add_User_Details } from "../actionTypes";
const initialState = {
  userDetails: { employeeId: '0',firstname: '', lastname: '', organizationalUnit:'',division:'',country:'', groups: [], allowProxy:0}
};

const userDetailsReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case Add_User_Details:
      newState.userDetails = {
        employeeId: action.userDetails.employeeId, firstname: action.userDetails.firstName, lastname: action.userDetails.lastName, organizationalUnit: action.userDetails.organizationalUnit,division: action.userDetails.division,country: action.userDetails.country, groups: action.userDetails.groups,allowProxy:action.userDetails.allowProxy
      };
      return newState;
    default:
      return state;
  }
};

export default userDetailsReducer;