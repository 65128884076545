//import { useMsal } from "@azure/msal-react";
import { msalInstance } from "../../index";
import Button from '@mui/material/Button';
export const SignOutButton = () => {
    //const { instance } = useMsal();
    //instance.acquireTokenSilent()
    const handleLogout = () => {
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    };
    return <Button variant="outlined" onClick={handleLogout}>Sign In</Button>;
    
  };