import React, { Suspense, useEffect, useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import '../../src/App.css'
import { GetProfilePicture } from '../Utilities/API/GetProfilePicture';
import { msalInstance } from "../index";
export default function ProfilePicture() {
    const [loginuserimage, setloginuserimage] = useState(null)
    const [IsImageFound, setIsImageFound] = useState(false)
    const [loginuserini, setloginuserini] = useState("")
    useEffect(() => {
        const account = msalInstance.getActiveAccount().name;

        if(account.split(',').length>1){
             var first=account.split(',')[0].trim().substring(0, 1);
             var second=account.split(',')[1].trim().substring(0, 1);
             setloginuserini(first+second);
        }
        else if(account.split(' ').length>1){
            var first=account.split(' ')[0].trim().substring(0, 1);
            var second=account.split(' ')[1].trim().substring(0, 1);
            setloginuserini(first+second);
        }
    },[loginuserini]);


    useEffect(() => {

       
        //get profile picture
        GetProfilePicture().then((response) => {
            //check response has error
            if (response.error) {
                //check if you are expecting specif error
                if (response.error === 404) {
                    //set unauthorizedUser to true
                    console.log(response);
                    setIsImageFound(false)
                }
                //in case of any error show initials
            }
            else {

                response.blob().then((data) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(data)
                    reader.onload = () => {
                        const base64data = reader.result;
                        setloginuserimage(base64data)
                        setIsImageFound(true)
                    };
                });

            }
        })
            .catch((e) => { console.log(e); });
    }, [loginuserimage]);

    return (
        <>
        {IsImageFound?<Avatar alt="Remy Sharp" src={loginuserimage} />:<Avatar>{loginuserini}</Avatar>}
        </>
    );
}