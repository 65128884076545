import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';

export default function UcEmployeeFilterActions(props) {
    const [options, setOptions] = useState([]);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const previousController = useRef();
    const userinfo = useSelector((state) => state.userDetailsReducer.userDetails);
    const [defaultValue, setDefaultValue] = useState('');
    const [value, setValue] = useState(null);
 

    const getData = (searchTerm) => {
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;

        var url = ApiURLs.EmployeeList + '?searchText=' + searchTerm
        GetAPI(url, null, signal).then((response) => {
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {  
                const updatedOptions = response.map((p) => {
                    return { title: p.employeeId + '-' + p.employeeName, value: p.employeeId };
                });
                setOptions(updatedOptions);
            }
        }).catch((e) => {
            CustomAlert(e, 'error');
        });
    }
    const onInputChange = (event, value, reason) => {
        if (value !== null)  {
            if (IsNullOrEmptyOrUndefined(value.trim()) === false && value.length > 2) {
                getData(value.trim());
            }
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue); 
        props.setSelectFilterError(!IsNullOrEmptyOrUndefined(newValue) ? 0 : 1); 
        if (newValue) {
            props.employeesSelected(newValue.value); // Pass only the employeeId
        } else {
            props.employeesSelected(null); // Clear the selection
        }
    };

    return (
        <>         
            <Autocomplete
                id="combo-box-select"
                size="small"
                options={options}
                disableClearable={false}
                getOptionLabel={(option) => option.title || ""}
                onInputChange={onInputChange}
                value={value}
                renderInput={(params) => 
                <TextField {...params} size="small"                
                label="Search Employee" required 
                error={props.selectFilterError === 1} // Set error style based on the error state
                helperText={props.selectFilterError === 1 ? 'Employee is required' : ''} // Display helper text for error
                />}
                readOnly={isReadOnly}
                onChange={handleChange}
            />
                
        </>
    );
}
