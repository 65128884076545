import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';
import { CardContent, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Typography, Tooltip } from '@mui/material';
import { CloudUpload, Delete, Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import {PostAPIFile} from '../../Utilities/API/PostAPIFile';
import {PutAPIFile} from '../../Utilities/API/PutAPIFile';
import { GetDownloadAPI } from '../../Utilities/API/GetDownloadAPI';
import {ApiURLs} from '../../Utilities/API/ApiURLs';
import { msalInstance } from '../..';
import { loginApiRequest } from '../../Utilities/authConfig';
import SearchDRs from './SearchDRs';

dayjs.extend(utc);

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const FILEEXT = '.txt,.html,.htm,.pdf,.docx,.doc,.xlsx,xls,.csv,.jpeg,.png';

export default function AddUpdate(props) {
    const [frequency, setFrequency] = React.useState('Daily');
    const [started, setStarted] = React.useState(new Date().toISOString());
    const [ended, setEnded] = React.useState(new Date().toISOString());
    const [performance, setPerformance] = React.useState('');
    const [focusArea, setFocusArea] = React.useState('');
    const [recognition, setRecognition] = React.useState('');
    const [cascade, setCascade] = useState('');
    const [innovation, setInnovation] = useState('');
    const [paction, setPAction] = useState('');
    const [raction, setRAction] = useState('');
    const [readonly, setReadOnly] = useState(props.ReadOnly);
    const [file, setFile] = React.useState(null);
    const [fileName, setFileName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [downloadLoading, setDownloadLoading] = React.useState(false);
    const [errors, setErrors] = useState({});

    const userinfo = useSelector((state) => state.userDetailsReducer.userDetails);
    const defaultUserName = userinfo.employeeId + "-" + userinfo.firstname + " " + userinfo.lastname;

    const [insertedById, setInsertedById] = useState();
    const [creatorName, setCreatorName] = useState(defaultUserName);


    useEffect(() => {
        if (IsNullOrEmptyOrUndefined(props.HuddleInfo.frequency) === false) {
            setCreatorName(props.HuddleInfo.insertedById + "-" +props.HuddleInfo.insertedByName)
            setFrequency(props.HuddleInfo.frequency)
            setPerformance(props.HuddleInfo.performance)
            setFocusArea(props.HuddleInfo.focusArea)
            setRecognition(props.HuddleInfo.recognition)
            setCascade(props.HuddleInfo.businessCascade)
            setInnovation(props.HuddleInfo.innovation)
            setPAction(props.HuddleInfo.previousAction)
            setRAction(props.HuddleInfo.reviewAction)

            setStarted(new Date(props.HuddleInfo.started));
            if (props.HuddleInfo.frequency !== 'Daily') {
                setEnded(new Date(props.HuddleInfo.ended));
            }
            else {
                setEnded(null);
            }
            if (IsNullOrEmptyOrUndefined(props.HuddleInfo.attachment) === false) {
                setFileName(props.HuddleInfo.attachment.split('-')[1])
            }
        }

    }, []);
    
    const handleFrequency = (e) => {
        setFrequency(e.target.value)
        if (e.target.value === 'Daily') {
            setStarted(new Date().toISOString());
        }
        if (e.target.value === 'Monthly') {
            var Today = new Date();
            setStarted(Today.toISOString()); 
            setEnded(new Date(Today.setDate(Today.getDate() + 30)).toISOString());
        }
        if (e.target.value === 'Weekly') {
            var Today = new Date();
            var wsd = Today.setDate(Today.getDate())
            var wed = Today.setDate(Today.getDate() + 6)
            setStarted(new Date(wsd).toISOString());
            setEnded(new Date(wed).toISOString());
        }
    }
    const handleStartDateChange = (e) => {
        const newStartDate = e.$d.toISOString();
        setStarted(newStartDate);
    
        // Adjust the Ended date based on the frequency
        if (frequency === 'Weekly') {
            setEnded(new Date(new Date(newStartDate).setDate(new Date(newStartDate).getDate() + 6)).toISOString());
        } else if (frequency === 'Monthly') {
            setEnded(new Date(new Date(newStartDate).setDate(new Date(newStartDate).getDate() + 30)).toISOString());
        }
    };

    const handleInsert = () => {
        setLoading(true);
        var end = (frequency === 'Daily') ? null : ended;
        const formData = new FormData();
        formData.append('EmployeeId', insertedById ? insertedById : userinfo.employeeId);
        formData.append('FileAttachment', file);
        formData.append('Frequency', frequency);
        formData.append('StartDate', dayjs(started).format('YYYY-MM-DD'));
        if (end !== null) formData.append('EndDate', dayjs(end).format('YYYY-MM-DD'));

        formData.append('Performance', performance);
        formData.append('focusArea', focusArea);
        formData.append('Recognition', recognition);
        formData.append('BusinessCascade', cascade);
        formData.append('Innovation', innovation);
        formData.append('PreviousAction', paction);
        formData.append('ReviewAction', paction);
        
        var url = ApiURLs.InsertHuddle;
        PostAPIFile(url, formData).then((response) => {
          setLoading(false);
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 400 && response.result !== null) {
              CustomAlert(response.msg, 'error');
             
            }
            else if (response.error === 400 && response.result === null) {
              CustomAlert(response.msg, 'error');
            }
        }
        else {
            CustomAlert(response.message, 'success');
            props.onClose();
            setPerformance('')
            setFocusArea('')
            setRecognition('')
            setCascade('')
            setInnovation('')
            setPAction('')
            setRAction('')
        }
      })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    }
    const handleUpdate = () => {
        setLoading(true);
        var end = (frequency === 'Daily') ? null : ended;
        const formData = new FormData();
        formData.append('Id', props.HuddleInfo.id);
        formData.append('EmployeeId', insertedById ? insertedById : userinfo.employeeId);
        formData.append('FileAttachment', file);
        formData.append('Frequency', frequency);
        formData.append('StartDate', dayjs(started).format('YYYY-MM-DD'));
        if (end !== null) formData.append('EndDate', dayjs(end).format('YYYY-MM-DD'));

        formData.append('Performance', performance);
        formData.append('focusArea', focusArea);
        formData.append('Recognition', recognition);
        formData.append('BusinessCascade', cascade);
        formData.append('Innovation', innovation);
        formData.append('PreviousAction', paction);
        formData.append('ReviewAction', paction);
        
        var url = ApiURLs.UpdateHuddle;
        PutAPIFile(url, formData).then((response) => {
          setLoading(false);
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
            if (response.error === 400 && response.result !== null) {
              CustomAlert(response.msg, 'error');
             
            }
            else if (response.error === 400 && response.result === null) {
              CustomAlert(response.msg, 'error');
            }
        }
        else {
            CustomAlert(response.message, 'success');
            props.onClose();
            setPerformance('')
            setFocusArea('')
            setRecognition('')
            setCascade('')
            setInnovation('')
            setPAction('')
            setRAction('')
        }
      })
        .catch((e) => {
            setLoading(false);
            CustomAlert(e, 'error');
        });
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            event.target.value = null;
            setFile(file);
            setFileName(file.name);
        }
    }
    const deleteFile = () => {
        setFile(null)
        setFileName('');
    }
    async function handleDownload(accessToken) {
        setDownloadLoading(true);
        var url = ApiURLs.DownloadAttachment + '?Id=' + props.HuddleInfo.id;
  
        try {
            if (!accessToken) {
                const account = msalInstance.getActiveAccount();
                if (!account) {
                    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
                }
                const response = await msalInstance.acquireTokenSilent({
                    ...loginApiRequest,
                    account: account
                });
                accessToken = response.accessToken;
            }
            GetDownloadAPI(url, accessToken)
                .then((response) => {
                  setDownloadLoading(false)
  
                  if (IsNullOrEmptyOrUndefined(response.error) === false) {
                    CustomAlert(response.msg, 'error')
                }
                else {
                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
                })
                .catch((e) => {
                    setDownloadLoading(false)
                    CustomAlert(e, 'error');
                });
        } catch (error) {
            setDownloadLoading(false)
            console.error('Error in handleDownload:', error);
            CustomAlert('Error downloading template', 'error');
        }
    };
    const employeesSelected = (value) => { 
        if (value !== null) {
            setInsertedById(value.value);
        }
    }
    const errorStyle = {
        borderColor: 'red',
        borderWidth: 1,
    };

    const handleInsertnUpdate = () => {
        const newErrors = {};
        if (!performance) newErrors.performance = 'Yesterday\'s Performance is required';
        if (!focusArea) newErrors.focusArea = 'Focus Area is required';
        if (!recognition) newErrors.recognition = 'Praise and Recognition is required';
        if (!cascade) newErrors.cascade = 'Business Cascade is required';
        if (!innovation) newErrors.innovation = 'Best Practice and Innovation is required';
        if (!paction) newErrors.paction = 'Q & A/Previous Actions Action is required';
        if (!raction) newErrors.raction = 'Review Actions is required';


        setErrors(newErrors);

        if (file) {
            const fileSizeInBytes = file.size;
            const fileSizeInMB = (fileSizeInBytes / (1024 * 1024))
            if (fileSizeInMB > 1) {
                CustomAlert('File size should not be more than a 1 MB.', 'info')
                return;
            }
        }
        const getDayDifference = (startDate, endDate) => {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const dayDifference = (end - start) / (1000 * 60 * 60 * 24);           
            return dayDifference;
        }
        
        const validateDates = () => {
            const dayDifference = getDayDifference(started, ended);
            
            if (frequency === 'Weekly') {
                if (dayDifference !== 6) {
                    CustomAlert('Date range should be 7 days for weekly frequency.','error');
                    return false;
                }
            } else if (frequency === 'Monthly') {
                if (dayDifference < 28 || dayDifference > 31) {
                    CustomAlert('Date range should be between 28 - 31 days for monthly frequency.','error');
                    return false;
                }
            }
            
            return true; 
        };

        if (props.Edit) {
            if (validateDates()) {  // Only proceed if validation passes
                if (Object.keys(newErrors).length === 0) {
                    handleUpdate();  // Proceed with the update if validation passes
                }
            }
        } else {
            if (validateDates()) {  // Only proceed if validation passes
                if (Object.keys(newErrors).length === 0) {
                    handleInsert();  // Proceed with the insert if validation passes
                }
            }
        }
    }
    return(
        <>
        <CardContent>
                            <Grid container spacing={1} columns={{ xs: 3.5, sm: 8, md: 12 }}>
                                <Grid item xs={12} style={{ marginTop: 5 }}>

                                    <Grid container spacing={1} style={{ marginBottom: 10 }}> 
                                        <Grid item xs={6 }marginTop={'6px'}>
                                            <SearchDRs employeesSelected={employeesSelected}></SearchDRs>
                                        </Grid>
                                        <Grid item xs={6} marginTop={'6px'}>
                                            <TextField fullWidth inputProps={{ readOnly: readonly, style: { fontSize: 16 } }} InputLabelProps={{ style: { fontSize: 13 } }} label = "Creator Name" defaultValue = {defaultUserName} variant="outlined" size="small" value={creatorName} disabled />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} marginTop={'6px'}>
                                            <FormControl disabled={readonly} sx={{ width: '100%'}} size="small" required>
                                                <InputLabel style={{ fontSize: 13 }} id="demo-simple-select-Method">Frequency</InputLabel>
                                                <Select id="demo-simple-Method" label="Frequency" style={{ fontSize: 13 }} value={frequency} onChange={handleFrequency}>
                                                    <MenuItem value={'Daily'} style={{ fontSize: 13 }}>{'Daily'}</MenuItem>
                                                    <MenuItem value={'Weekly'} style={{ fontSize: 13 }}>{'Weekly'}</MenuItem>
                                                    <MenuItem value={'Monthly'} style={{ fontSize: 13 }}>{'Monthly'}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6} marginTop={'6px'}>
                                            {
                                                frequency === 'Daily' &&
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker disabled={readonly} label="Date Of Huddle" slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: 220, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format={'DD-MM-YYYY'} value={dayjs(started)} onChange={(e) => setStarted(e.$d.toISOString())} />
                                                </LocalizationProvider>
                                            }
                                            {
                                                frequency !== 'Daily' &&
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <DatePicker disabled={readonly} label="Started" slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: '100%', '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format={'DD-MM-YYYY'} value={dayjs(started)} onChange={handleStartDateChange} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <DatePicker disabled={readonly} label="Ended" slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: '100%', '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format={'DD-MM-YYYY'} value={dayjs(ended)} onChange={(e) => setEnded(e.$d.toISOString())} />
                                                        </Grid>
                                                    </Grid>
                                                </LocalizationProvider>
                                            }

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>                                     
                                        <Grid item xs={6} marginTop={'6px'}>
                                            <TextField required multiline rows={3} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Yesterday's Performance" variant="outlined"  value={performance} 
                                            onChange={(e) => { setPerformance(e.target.value); setErrors({ ...errors, performance: '' })}}
                                            error={!!errors.performance}
                                            helperText={errors.performance}
                                            style={{ width: '100%', ...(errors.performance ? errorStyle : {}) }} />
                                        </Grid>
                                        <Grid item xs={6} marginTop={'6px'}>
                                            <TextField required multiline rows={3} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Focus Area" variant="outlined" value={focusArea} 
                                            onChange={(e) => {setFocusArea(e.target.value); setErrors({ ...errors, focusArea: '' })}}
                                            error={!!errors.focusArea}
                                            helperText={errors.focusArea}
                                            style={{ width: '100%', ...(errors.focusArea ? errorStyle : {}) }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                                        <Grid item xs={6}>
                                            <TextField required multiline rows={3} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Praise and Recognition" variant="outlined" value={recognition} 
                                              onChange={(e) => {setRecognition(e.target.value); setErrors({ ...errors, recognition: '' })}} 
                                              error={!!errors.recognition}
                                              helperText={errors.recognition}
                                              style={{ width: '100%', ...(errors.recognition ? errorStyle : {}) }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField required multiline rows={3} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Business Cascade" variant="outlined" value={cascade} 
                                             onChange={(e) => {setCascade(e.target.value); setErrors({ ...errors, cascade: '' })}} 
                                             error={!!errors.cascade}
                                             helperText={errors.cascade}
                                             style={{ width: '100%', ...(errors.cascade ? errorStyle : {}) }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                                        <Grid item xs={6}>
                                            <TextField required multiline rows={2} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Best Practice and Innovation" variant="outlined"  value={innovation} 
                                              onChange={(e) => {setInnovation(e.target.value); setErrors({ ...errors, innovation: '' })}}
                                              error={!!errors.innovation}
                                              helperText={errors.innovation}
                                              style={{ width: '100%', ...(errors.innovation ? errorStyle : {}) }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField required multiline rows={2} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Q & A/Previous Actions" variant="outlined" value={paction} 
                                            onChange={(e) => {setPAction(e.target.value); setErrors({ ...errors, paction: '' })}} 
                                            error={!!errors.paction}
                                            helperText={errors.paction}
                                            style={{ width: '100%', ...(errors.paction ? errorStyle : {}) }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                                        <Grid item xs={6}>
                                            <TextField required multiline rows={2} inputProps={{ readOnly: readonly, maxLength: 5000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Review Actions" variant="outlined" value={raction} 
                                            onChange={(e) => {setRAction(e.target.value); setErrors({ ...errors, raction: '' })}} 
                                            error={!!errors.raction}
                                            helperText={errors.raction}
                                            style={{ width: '100%', ...(errors.raction ? errorStyle : {}) }} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button component="label" className='App-Button' role={undefined} disabled={readonly} variant="contained" tabIndex={-1} startIcon={<CloudUpload />} style={{ textTransform: 'none' }}>Select file<VisuallyHiddenInput type="file" onChange={handleFileChange} accept={FILEEXT} /></Button> {' '}
                                            {(fileName.length > 0) && <Tooltip title={'Delete'}><Button variant="outlined" disabled={readonly} style={{ textTransform: 'none', paddingLeft: 2, paddingRight: 2, minWidth: 45 }} onClick={deleteFile} color='error'><Delete /></Button></Tooltip>}{' '}
                                    
                                            {(fileName.length > 0) && (props.Edit === true ) && <Tooltip title={'Download'}><Button variant="outlined" style={{ textTransform: 'none', paddingLeft: 2, paddingRight: 2, minWidth: 45 }} onClick={handleDownload} color='error'><Download /></Button></Tooltip>}

                                            <Typography style={{ fontSize: 13 }}>{fileName}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: '5px' }}>
                                        <Grid item xs={12}>
                                            {props.ReadOnly === false && <LoadingButton className='App-Button'  disableElevation variant='contained' loading={loading} style={{ textTransform: 'none' }} onClick={handleInsertnUpdate}>{props.Edit === true ? 'Update' : 'Submit'}</LoadingButton>}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </CardContent>
        </>
    )
}