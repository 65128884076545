import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Card, Button,TextField, Divider,Grid, Tooltip, Typography,Box,InputLabel, MenuItem, Select,FormControl} from '@mui/material';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PutAPI } from '../../Utilities/API/PutAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';
import Switch from '@mui/material/Switch';


export default function UcUserAcessUpdateUser(props) {
    const [role, setRole] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [loading, setLoading] = useState(false);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [checked, setChecked] = useState(true);
    const [Country, setCountry] = useState('');
    const [Division, setDivision] = useState('');
    const [BusinessUnit, setBusinessUnit] = useState('');
    const [fullName, setFullName] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [rowid, setRowId] = useState(0);


    useEffect(() => {
        if (props.data) {
            // Update state with props.data values
            setRowId(props.data.id || 0);
            setCountry(props.data.countryId || '');
            setDivision(props.data.divisionId || '');
            setBusinessUnit(props.data.organizationalUnitId || '');
            setEmployeeId(props.data.employeeId || '');
            setFullName(props.data.employeeName || '');
            setSelectedRole(props.data.groupId || '');
            if(props.data.active === 'Inactive')
            {
                setChecked(false);
            }
            else
            {
                setChecked(true);
            }
        }
    }, [props.data]);

    useEffect(() => {
        getGroupList();
      }, []);

    const getGroupList = () => {
        setLoading(true);
        var url = ApiURLs.GroupList; 
        GetAPI(url).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                setRole(response);           
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    }
    const UpdateUserRoleMapping = () => {
        setLoading(true);
        var url = ApiURLs.UpdateUserRoleMapping;
        var model = { id:rowid, groupId:selectedRole,active:checked ? 1 : 0 };
        PutAPI(url, model).then((response) => {
            setLoading(false);
            if (IsNullOrEmptyOrUndefined(response.error) === false) {
                if (response.error === 404) {
                    CustomAlert('Unauthrize error, try to login again', 'error');
                } else {
                    CustomAlert(response.msg, 'error');
                }
            }
            else {
                CustomAlert(response.message, 'success');
                props.onClose();
            }
        })
            .catch((e) => {
                setLoading(false);
                CustomAlert(e, 'error');
            });
    }
    const handleRoleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedRole(selectedValue);
    }; 
    const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
      };

    return (
        <Box p={3}> 
            <Grid container justifyContent="flex-end">
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClose()}>
                <CloseIcon  className='App-Icons'/>
                </IconButton>
            </Grid>
            <Typography variant="h6" component="h6">
                Update User
            </Typography>
            <Divider />
            <Grid container spacing={2} style={{marginTop:2}}>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small" required>
            <InputLabel>Role</InputLabel>
            <Select  
                value={selectedRole}
                onChange={handleRoleSelectChange}      
                label="Role"
            >
                <MenuItem value="" disabled>Select Role</MenuItem>
                {role.map((row) => (
                <MenuItem key={row.id} value={row.id}>{row.groupName}</MenuItem>
                ))}
            </Select>                              
            </FormControl>
            </Grid>           
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth 
                value={Country}
                label="Country"
                variant="outlined"
                size="small"
                disabled            
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth 
                value={Division}             
                label="Division"
                variant="outlined"
                size="small"
                disabled           
            />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth
                value={BusinessUnit}
                label="Business Unit"
                variant="outlined"
                size="small"
                disabled
            />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                fullWidth 
                value={employeeId}          
                label="Employee ID"
                variant="outlined"
                size="small"
                disabled
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                fullWidth  
                value={fullName}         
                label="Full Name"
                variant="outlined"
                size="small"
                disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} container alignItems="center">
            <Tooltip title='Active/Inactive'>
                <Switch {...label} checked={checked} onChange={handleSwitchChange} />
            </Tooltip>
            <Box ml={2}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'none', fontSize: 15, width: 120 }}
                    onClick={UpdateUserRoleMapping}
                    className='App-Button'
                >
                    Update
                </Button>
            </Box>
            </Grid>
        </Grid>
        </Box>
    )
}