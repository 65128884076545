import React, { Suspense, useEffect, useRef, useState } from 'react';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { IconButton } from '@mui/material';
import '../../src/App.css'
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EdgeBrowserNotificationImage from '../AppImages/EdgeBrowser.PNG'
export default function AllowNotification() {
    const [isNotificationAllowed, setisNotificationAllowed] = useState(true);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    useEffect(() => {

        if (Notification.permission === "granted") {
            setisNotificationAllowed(true);
        }
        else {
            setisNotificationAllowed(false);
        }
       

    }, []);

    const handleIconButtonClick = async () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {!isNotificationAllowed ?
                <Tooltip title="Notification Disabled">
                    <IconButton onClick={handleIconButtonClick}><NotificationsOffIcon className="App-Icons" /></IconButton>
                </Tooltip>

                : <></>
            }
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <NotificationsOffIcon className="App-Icons" /> {"Notification Disabled"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Notifications for this site are currently turned off in your browser. To receive updates from the PulseMeter site, please enable notifications in your browser settings.
                    </DialogContentText>


                    <img
                        src={EdgeBrowserNotificationImage}
                        alt="Microsoft Edge"
                        loading="lazy"
                        width={500}
                    />


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

