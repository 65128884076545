import { loginApiRequest } from "../authConfig";
import { msalInstance } from "../../index";
//this function used to call only get apis.
// data sample => {id:10,Name:"Mayank",Age:30,DOB:new Date()}
export async function PostAPIFile(url, formData, accessToken,signal=null) {
    if (!accessToken) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const response = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "POST",
        headers: headers,signal,
        body: formData
    };

    return fetch(url, options)
        .then((response, reject) => {           
            if (response.ok) {             
                return response.json()
            }
            else {
                if (response.status === 400){
                        return response.json().then((errorObj) =>{                        
                            return (Promise.reject({ error: errorObj.statusCode, msg: errorObj.message, result: errorObj.result }))
                        }
                    );
                }
                else{
                    return (Promise.reject({ error: response.status, msg: response.statusText }))  
                }
            }
        }
        )
        .catch(error => {return error; });
}