import React from "react";
//import "./LoadingSpinner.css";
import { CircularProgress, Fab } from '@mui/material';

export const LoadingSpinner = (props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', position: 'absolute', minWidth: '100%', left: 0, top: '46%' }}>
      <Fab disabled={true} style={{ backgroundColor: '#fff', boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(75, 22, 22, 0.12)' }}>{<CircularProgress size={35} style={{ color: '#1976d2' }} />}</Fab>
    </div>
  );
}