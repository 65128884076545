import React from "react";
import Backdrop from '@mui/material/Backdrop';
import Clock from '../../Images/Upside-down-face.gif';
export const LoadingClock = (props) => {
    const [open, setOpen] = React.useState(true);
    return (

        <Backdrop
            sx={{  zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}
        >
            <img src={Clock} width={100} height={100} alt="loading..." color="inherit" />
        </Backdrop>
    );
}