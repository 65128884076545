import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton,Card, Button,TextField, Divider,Grid, Typography,Box,InputLabel, MenuItem, Select,FormControl} from '@mui/material';
import { GetAPI } from '../../Utilities/API/GetAPI';
import { PostAPI } from '../../Utilities/API/PostAPI';
import { ApiURLs } from '../../Utilities/API/ApiURLs';
import { CustomAlert } from '../../Utilities/CustomAlert';
import { IsNullOrEmptyOrUndefined } from '../../Utilities/Utilities';

export default function UcAddNewBusinessHeadMapping(props) {
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState('');
    const [businessUnitList, setBusinessUnitList] = useState([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [headEmployeeId, setHeadEmployeeId] = useState("");
    const [headEmployeeName, setHeadEmployeeName] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    

    useEffect(() => {
      getCountryList();
    }, []);

    
    const getCountryList = () => {
      setLoading(true);
      var url = ApiURLs.GetCountryList; 
      GetAPI(url).then((response) => {
          setLoading(false);
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
              if (response.error === 404) {
                  CustomAlert(response.msg, 'error');
              } else {
                  CustomAlert(response.msg, 'error');
              }
          }
          else {
              setCountryList(response);
          }
      })
          .catch((e) => {
              setLoading(false);
              CustomAlert(e, 'error');
          });
    }
    const getDivisionList = (selectedValue) => {
      setLoading(true);
      var url = ApiURLs.GetDivisionList + '?countryId=' + selectedValue;
      GetAPI(url).then((response) => {
          setLoading(false);
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
              if (response.error === 404) {
                  CustomAlert(response.msg, 'error');
              } else {
                  CustomAlert(response.msg, 'error');
              }
          }
          else {
              setDivisionList(response);
          }
      })
          .catch((e) => {
              setLoading(false);
              CustomAlert(e, 'error');
          });
    }
    const getBusinessUnitList = (selectedDivisionValue) => {
      setLoading(true);
      
      var url = ApiURLs.GetBusinessUnitList +'?divisionId=' +selectedDivisionValue+ '&countryId=' + selectedCountry;
      GetAPI(url).then((response) => {
          setLoading(false);
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
              if (response.error === 404) {
                  CustomAlert(response.msg, 'error');
              } else {
                  CustomAlert(response.msg, 'error');
              }
          }
          else {
              setBusinessUnitList(response);
          }
      })
          .catch((e) => {
              setLoading(false);
              CustomAlert(e, 'error');
          });
    }    
    const handleCountrySelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedCountry(selectedValue);
      setSelectedDivision('');
      setSelectedBusinessUnit('');
      setDivisionList([]);
      setBusinessUnitList([]);
      if (selectedValue && selectedValue !== ' ') {
        getDivisionList(selectedValue);
      }
    }; 
    const handleDivisionSelectChange = (event) => {
      const selectedDivisionValue = event.target.value;
      setSelectedDivision(selectedDivisionValue);
      setSelectedBusinessUnit('');
      setBusinessUnitList([]);
      if (selectedDivisionValue && selectedDivisionValue !== ' ') {
        getBusinessUnitList(selectedDivisionValue);
      }
    };
    const handleBusinessUnitSelectChange = (event) => {
      setSelectedBusinessUnit(event.target.value);
    };
    const AddBussinessUnitHeadDetails = () => {
      setLoading(true);
      var parameters = {countryId:selectedCountry,divisionId:selectedDivision,organizationalUnitId:selectedBusinessUnit,headEmployeeId:headEmployeeId,headEmployeeName:headEmployeeName};
      PostAPI(ApiURLs.AddBusinessUnitHeadDetails,parameters).then((response) => { 
        setLoading(false);  
          if (IsNullOrEmptyOrUndefined(response.error) === false) {
              if (response.error === 404) {
                  CustomAlert('Unauthrize error, try to login again', 'error');
              }
              else if (response.error === 400) {
                    CustomAlert(response.msg, 'error');
              }
            }                 
          else {
              if(response.statusCode!= 0){
                CustomAlert(response.message, 'success'); 
                props.onClose();
              }
              else{
                CustomAlert(response.message, 'error');                
              }
            }
          })
          .catch((e) => {
              setLoading(false);
              CustomAlert(e, 'error');
          });
    }
    const errorStyle = {
      borderColor: 'red',
      borderWidth: 1,
    };
    const onSubmit = () => {
      const newErrors = {};
      if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
      if (!selectedDivision) newErrors.selectedDivision = 'Division is required';
      if (!selectedBusinessUnit) newErrors.selectedBusinessUnit = 'Business unit is required';    
      if (!headEmployeeId) newErrors.headEmployeeId = 'BU head Emp ID is required';
      if (!headEmployeeName) {
        newErrors.headEmployeeName = 'BU head Full Name is required';
      }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      AddBussinessUnitHeadDetails();
    }
  };
  
  const handleEmployeeName = (e) => {
    const { value } = e.target;
    const newErrors = {};
    // Validate if the input only contains letters and spaces
    const regex = /^[A-Za-z\s]*$/;
    if (!regex.test(value)) {
      newErrors.headEmployeeName = 'Only letters and spaces are allowed';
    } else if (value.length > 200) {
      newErrors.headEmployeeName = 'Character limit is exceeded';
    } else {
      setHeadEmployeeName(value);
    }

    setErrors(newErrors);
  };
  const handleEmployeeId = (e) => {
    const { value } = e.target;
    const newErrors = {};
    // Validate if the input only contains letters and spaces
    const regex = /^[0-9]*$/;
    if (!regex.test(value)) {
      newErrors.headEmployeeId = 'Only digits are allowed';
    } else if (value.length > 10) {
      newErrors.headEmployeeId = 'Digits limit is exceeded';
    } else {
      setHeadEmployeeId(value)
    }

    setErrors(newErrors);
  };

     

  return (
    <Box p={3}> 
    <Grid container justifyContent="flex-end">
        <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClose()}>
          <CloseIcon className='App-Icons' />
        </IconButton>
      </Grid>
      <Typography variant="h6" component="h6" gutterBottom>
      Add New Business Head
      </Typography>
    <Divider />
    <Grid container spacing={2} style={{marginTop:2}}>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedCountry}>
          <InputLabel style={errors.selectedCountry ? errorStyle : {}}>Country</InputLabel>
          <Select
            value={selectedCountry}
            onChange={handleCountrySelectChange}
            label="Country"
            style={errors.selectedCountry ? errorStyle : {}}
          >
            <MenuItem value="" disabled>Select Country</MenuItem>
            {countryList.map((row) => (
              <MenuItem key={row.id} value={row.id}>{row.country}</MenuItem>
            ))}
          </Select>
          {errors.selectedCountry && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedCountry}</div>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedDivision}>
          <InputLabel style={errors.selectedDivision ? errorStyle : {}}>Division</InputLabel>
          <Select
            value={selectedDivision}
            onChange={handleDivisionSelectChange}
            label="Division"
            style={errors.selectedDivision ? errorStyle : {}}
          >
            <MenuItem value="" disabled>Select Division</MenuItem>
            {divisionList.map((row) => (
              <MenuItem key={row.id} value={row.id}>{row.division}</MenuItem>
            ))}
          </Select>
          {errors.selectedDivision && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedDivision}</div>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined" size="small" required error={!!errors.selectedBusinessUnit}>
          <InputLabel style={errors.selectedBusinessUnit ? errorStyle : {}}>Business unit</InputLabel>
          <Select
            value={selectedBusinessUnit}
            onChange={handleBusinessUnitSelectChange}
            label="Business unit"
            style={errors.selectedBusinessUnit ? errorStyle : {}}
          >
            <MenuItem value="" disabled>Select Business unit</MenuItem>
            {businessUnitList.map((row) => (
              <MenuItem key={row.id} value={row.id}>{row.businessUnit}</MenuItem>
            ))}
          </Select>
          {errors.selectedBusinessUnit && <div style={{ color: '#d32f2f', fontSize: '0.75rem' }}>{errors.selectedBusinessUnit}</div>}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          value={headEmployeeId}
          onChange={handleEmployeeId}
          label="BU Head Emp ID"
          variant="outlined"
          size="small"
          required
          error={!!errors.headEmployeeId}
          helperText={errors.headEmployeeId}
          style={errors.headEmployeeId ? errorStyle : {}}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          value={headEmployeeName}
          onChange={handleEmployeeName}
          label="BU Head Full Name"
          variant="outlined"
          size="small"
          required
          error={!!errors.headEmployeeName}
          helperText={errors.headEmployeeName}
          style={errors.headEmployeeName ? errorStyle : {}}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="contained"
          className='App-Button'
          style={{ textTransform: 'none', fontSize: 15, width: 120 }}
          onClick={onSubmit}
        >
          Add
        </Button>
      </Grid>
    </Grid>
    </Box>
  );
}
